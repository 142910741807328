import React, { useState, useEffect } from "react";

import {
    Box,
    Flex,
    Text,
    Textarea,
    SlideFade,
    Spacer,
    Tooltip,
    Button,
    Heading,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    CircularProgress,
    CircularProgressLabel,
    UnorderedList,
    ListItem,
    Center,
    Alert,
    AlertIcon
} from "@chakra-ui/react"

import SVG from "./SVG";

import StepProgress from "./StepProgress.jsx";
import { CheckCircleIcon } from '@chakra-ui/icons'

const StepContainer = (props) => {

    const { steps, step, setStep, save, formInfo, globalFormInfo, duration, setNote, hidePanel, getStepProgress } = props;

    const [isShowing, setShow] = useState(false);
    const [isSaving, setSaving] = useState(false);

    useEffect(() => {
        setShow(true);
        return () => setShow(false)
    }, []);

    const boxPadding = 5;

    const stepName = Object.keys(steps)[step];
    const note = steps[stepName].data.note;

    const handleSaveAndContinue = () => {
        setSaving(true);
        save(true, () => {
            setSaving(false);
            setStep(step + 1);
        });
    }


    const formInfoData = globalFormInfo || formInfo || {};
    const progress = getStepProgress(Object.values(steps)[step]);

    return (
        <Box flex={7} w="10vw">
            <SlideFade in={isShowing} transition={{ enter: {duration}, exit: {duration} }}>
                <Flex minHeight={"calc(100vh - 60px)"}>
                    <Box flex={hidePanel ? 7 : 5} padding={boxPadding} maxHeight={"calc(100vh - 60px)"} overflow={"auto"}>
                        {props.children}
                    </Box>
                    <Box
                        display={hidePanel && "none"}
                        flex={2}
                        boxShadow="xl"
                        padding={boxPadding}
                        position="relative"
                        overflowY="scroll"
                        maxHeight={"calc(100vh - 60px)"}
                        >

                        <SVG src={formInfoData.image} width="100%"/>

                        {formInfoData.heading && (
                            <Heading mb="20px" size="lg">{formInfoData.heading}</Heading>
                        )}

                        {formInfoData.text && typeof formInfoData.text === "string" && (
                            <Text mb="20px">{formInfoData.text}</Text>
                        )}

                        {formInfoData.body && (formInfoData.body)}
                        
                        <Accordion allowToggle>
                            <AccordionItem>
                                <h3>
                                    <AccordionButton px="0">
                                        <Box flex="1" textAlign="left">
                                            Extra Notes
                                        </Box>
                                        <AccordionIcon />
                                    </AccordionButton>
                                </h3>
                                <AccordionPanel p="0">
                                <Textarea
                                    defaultValue={note}
                                    placeholder="Write notes here.."
                                    onChange={(e) => setNote(e.target.value)}
                                    size="sm"
                                    height={200}
                                    maxHeight={500}
                                />
                                </AccordionPanel>
                            </AccordionItem>
                        </Accordion>
                        
                        <Center pt={10}>
                            <StepProgress progress={progress} size="150px" showColor={true}/>
                        </Center>
                        <br />
                        {progress && progress.incomplete && progress.incomplete.length > 0 && (
                            progress.incomplete.map(item => (
                            <Alert status="error" key={"progress-incomplete-" + item}>
                                <AlertIcon />
                                {item}
                            </Alert>
                            ))
                        )}
                    

                        <Flex pos="absolute" left={0} w="100%" bottom={0} borderTopWidth={1} bg={"#FFF"}>
                            <Box p="4">
                            </Box>
                            <Spacer />
                            <Box p="4">
                                <Button
                                    rightIcon={<CheckCircleIcon />}
                                    colorScheme="purple"
                                    variant="solid"
                                    onClick={handleSaveAndContinue}
                                    isLoading={isSaving}
                                    loadingText="Saving"
                                    isDisabled={!(progress?.percent === 100)}
                                >Save & Continue</Button>
                            </Box>
                        </Flex>
                    </Box>
                </Flex>
            </SlideFade>
        </Box>
    );
}

export default StepContainer;