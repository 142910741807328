import React, { useState } from "react"

import {
	DragDropContext,
	Droppable,
	Draggable
} from 'react-beautiful-dnd';


import { DeleteIcon, ArrowForwardIcon } from '@chakra-ui/icons'

import {
	Container,
	Image,
	List,
	ListItem,
	Button,
	IconButton,
	HStack,
	Center,
	Heading,
	Divider,
	Box,
	Flex,
	Spacer
} from "@chakra-ui/react";


import InsertSection from "./InsertSection";
import SectionPreview from "./SectionPreview";
import PageBreadcrumbs from "./PageBreadcrumbs";


const RenderPreview = (props) => {
	const {
		page,
		pages,
		changePage,
		selectedSection,
		sections,
		deleteSection,
		handleNewSection,
		setSectionNotes,
		pageData,
		duration,
		selectedPreview,
		setSelectedPreview,
	} = props;

	const [hoveringSection, setHoveringSection] = useState(false);

	const selectedSectionData = sections[selectedSection];

	if(pageData) {
		return (
			<Container maxW="container.xl" h="calc(100vh - 60px)" overflowY="auto">
	
				<Flex
					textAlign="center"
					position="sticky"
					top={0}
					zIndex={!selectedSectionData && 6}
					paddingTop={2}
					paddingBottom={2}
					fontWeight={400}
					marginTop={3}
					bg={!selectedSectionData && "white"}
				>
					{/* {selectedSectionData ? "_" : pageData?.title} */}
					<PageBreadcrumbs
						pages={pages}
						page={page}
						changePage={changePage}
					/>
					<Spacer />
					<Box>
						
					</Box>
				</Flex>

				<br />

				{pageData.sections && pageData.sections.length < 1 && <Box>
					<Center padding={10} margin={"auto"} textAlign={"center"}>
						<Heading size={"md"}>
							Page is empty, select one to add <ArrowForwardIcon />
						</Heading>
					</Center>
					<InsertSection
						index={0}
						sectionData={selectedSectionData}
						selectedSection={selectedSection}
						handleNewSection={handleNewSection}
					/>
				</Box>}
				

				{pageData.sections && pageData.sections.length > 0 && <Droppable droppableId="renderPreview">
				{(provided) => (
					<List className="renderPreview" {...provided.droppableProps} ref={provided.innerRef} borderWidth={3} borderColor={"gray.200"}>
					{pageData?.sections.map((section, index) => {
						return (
						<Draggable key={section.id} draggableId={section.id} index={index} dragType="render" isDragDisabled={!!selectedSection}>
							{(provided) => (
							<ListItem ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} position="relative">
								<SectionPreview
									index={index}
									section={section}
									duration={duration}
									sectionData={selectedSectionData}
									selectedSection={selectedSection}
									handleNewSection={handleNewSection}
									deleteSection={deleteSection}
									setSectionNotes={setSectionNotes}
									hoveringSection={hoveringSection}
									setHoveringSection={setHoveringSection}
									selectedPreview={selectedPreview}
                                    setSelectedPreview={setSelectedPreview}
								/>
							</ListItem>
							)}
						</Draggable>
						);
					})}
	
					{(pageData.sections.length === 0) && (
						<InsertSection
							index={0}
							sectionData={selectedSectionData}
							selectedSection={selectedSection}
							handleNewSection={handleNewSection}
						/>
						)}
					{provided.placeholder}
					</List>
				)}
				</Droppable>}
			</Container>
		);
	} else {
		return (
			<Center padding={10} margin={"auto"} textAlign={"center"}>
				<Heading size={"md"}>
					Please select a page or &nbsp;
					<Button
						fontSize={"lg"}
						// variant={"link"}
						onClick={() => document.querySelector("#add-new-page-button").click()}
					>
						create one
					</Button>
				</Heading>
			</Center>
		);
	}
}


export default RenderPreview;