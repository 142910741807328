import React, { useState, useEffect, useRef } from "react";
import { Buffer } from 'buffer';
import { globalData } from "../lib/GlobalState.js";
import { useApolloClient } from '@apollo/client';
import { RUN_HEARTBEAT, SAVE_STRATEGY } from '../apollo/queries.js';
import jwt_decode from "jwt-decode";
import uniqid from "uniqid";
import { useToast } from "@chakra-ui/react";
import FullscreenLoader from "../components/FullscreenLoader";
import LoginForm from "../components/LoginForm.jsx";
import StrategySelect from "../components/StrategySelect.jsx";
import StrategySession from "../components/StrategySession.jsx";
import hourlyRate from "../../hourlyRate";

const windowGlobal = typeof window !== 'undefined' && window;

function useInterval(callback, delay) {
	const savedCallback = useRef();
  
	// Remember the latest callback.
	useEffect(() => {
	  savedCallback.current = callback;
	}, [callback]);
  
	// Set up the interval.
	useEffect(() => {
	  let id = setInterval(() => {
		savedCallback.current();
	  }, delay);
	  return () => clearInterval(id);
	}, [delay]);
}



const IndexPage = () => {
	const toast = useToast()
	const [loading, setLoading] = useState(true);

	const [loggedIn, setLoggedIn] = useState(windowGlobal ? (
		localStorage.getItem('user-token') ? jwt_decode(localStorage.getItem('user-token')) : false
	) : false);

	const [clientData, setClientData] = useState(false);

	const [strategy, setStrategy] = useState(false);

	const [step, setStep] = useState(globalData.step);
	const [steps, setSteps] = useState(globalData.steps);

	const [page, setPage] = useState(globalData.page);
	const [pages, setPages] = useState(globalData.pages);

	const [saves, setSaves] = useState([]);

	const [name, setName] = useState(globalData.name);

	const [sections, setSections] = useState(false);

	const [selectedSection, setSelectedSection] = useState(false);

	const [globalFormInfo, setGlobalFormInfo] = useState(false);

	const [isSaving, setIsSaving] = useState(false);

	const [stage, setStage] = useState(false);

	const [feedback, setFeedback] = useState([]);

	const [,forceUpdate] = useState(0);

	const client = useApolloClient();

	const data = {
		//settings
		duration: 0.2,
		hourlyRate,

		saves,

		loggedIn,
		setLoggedIn,

		clientData,
		setClientData,

		login(authToken) {
			if(windowGlobal) {
				localStorage.setItem("user-token", authToken);
				const decoded = jwt_decode(authToken);
				data.setLoggedIn(decoded);
			}
		},

		logout() {
			if(windowGlobal) {
				localStorage.removeItem("user-token");
				setLoggedIn(false);
				setStrategy(false);
				setClientData(false);
				data.reload();
			}
		},

		isAdmin() {
			return clientData?.roles?.nodes[0].name === "administrator";
		},

		heartbeat(dataLoggedIn) {
			if(dataLoggedIn) {
				const userId = +(dataLoggedIn?.data?.user?.id);
				client.query({
					query: RUN_HEARTBEAT,
					fetchPolicy: "network-only",
					variables: { id: userId }
				}).then((res) => {
					if(!clientData) {
						setClientData(res?.data?.user);
					}
				}).catch(e => {
					// console.log(e.message);
					data.logout();
				});
			}
		},

		strategy,
		setStrategy,

		step,
		setStep: (_ => {
			setGlobalFormInfo(false);
			setStep(_)
		}),

		steps,
		setSteps,

		sections,
		setSections,

		selectedSection,
		setSelectedSection,
		
		page,
		setPage,

		pages,
		setPages,

		name,
		setName,
		
		feedback,
		setFeedback,

		stage,
		setStage,

		globalFormInfo,
		setGlobalFormInfo,

		isSaving,

		encodeGlobalId(type, id) {
			const buff = Buffer.from(`${type}:${id}`, 'utf-8');
			const globalId = buff.toString('base64');
			return globalId;
		},

		decodeGlobalId(id) {
			const buff = Buffer.from(id, 'base64');
			const decodedId = buff.toString('utf-8');
			const split = decodedId.split(':');
			return {
				type: split[0],
				id: +split[1]
			}
		},

		importData({ id, name, session, client, stage, feedback }) {
			const sessionData = JSON.parse(session);
			
			data.setStep(sessionData.step);
			data.setSteps(sessionData.steps);

			data.changePage(sessionData.page);
			data.setPages(sessionData.pages);

			data.setName(name);
			
			data.setClientData({...data.clientData, info: client ?? {}});

			data.setFeedback(feedback || []);

			data.setStage(stage || 'in_progress');
			
			data.setStrategy(id);
		},
		exportData() {
			const obj = {};

			obj.step = data.step;
			obj.steps = data.steps;

			obj.page = data.page;
			obj.pages = data.pages;
			
			obj.name = data.name;

			return JSON.stringify(obj);
		},

		error(description) {
			toast({
				title: "An error occurred",
				description,
				status: "error",
				position: "bottom",
				duration: 3000,
				isClosable: true,
			})
		},

		save(showToast=true, callback=false, stage=false) {
			if(data.strategy) {
				setSaves(data.saves);
				data.saves.push(data.name);

				setTimeout(() => {
					const strategyStage = stage || data.stage || "in_progress";
					const last = data.saves.shift();
					setSaves(data.saves);
					if(data.saves.length === 0) {
						setIsSaving(true);
						const decodedId = data.decodeGlobalId(data.strategy);
						const strategyId = decodedId.id;
						
						const progress = data.getTotalProgress(strategyStage);
						const variables = {
							id: strategyId,
							title: data.name,
							sessionData: data.exportData(),
							strategyStage,
							feedback: JSON.stringify(data.feedback || []),
							progress
						};

						client.mutate({
							mutation: SAVE_STRATEGY,
							variables
						}).then((res) => {
							if(showToast) {
								toast({
									title: "Session saved.",
									description: "Your current strategy session has been saved.",
									status: "success",
									duration: 2000,
									isClosable: true,
								})
							}

							setIsSaving(false);

							if(typeof callback === "function") {
								callback();
							}
						}).catch(e => {
							data.error(e.message);
							setIsSaving(false);
						});
					}
				}, 200);
			}
		},

		removeProp(obj, propToDelete) {
			for (var property in obj) {
			   if (typeof obj[property] == "object") {
				  delete obj.property
				  let newJsonData= data.removeProp(obj[property], propToDelete);
				  obj[property]= newJsonData
			   } else {
				   if (property === propToDelete) {
					 delete obj[property];
				   }
				 }
			 }
			 return obj
		},

		getStepProgress(subSteps) {
			const total = Object.keys(subSteps).length - 1;
			let completed = 0;
			let incomplete = [];
			for (const property in subSteps) {
				if(property !== "data" && subSteps[property].completed) {
					completed++;
				} else if(property !== "data") {
					incomplete.push(property);
				}
			}

			if(total) {
				let percent = Math.round((100 * completed) / total);
				let color = 'red.500';

				if(percent > 33) {
					color = 'orange.300';
				}
				if(percent === 100) {
					color = 'green.500';
				}

				if(percent === 0) {
					percent = 0.0001
				}

				return {
					completed,
					total,
					percent,
					color,
					incomplete
				}
			}
		},

		getTotalProgress(strategyStage=false) {
			let total = 0;
			let completed = 0;

			for (const property in data.steps) {
				const progress = data.getStepProgress(data.steps[property]);
				if(progress) {
					total += progress.total;
					completed += progress.completed;
				}
			}

			let percent = Math.round((80 * completed) / total);

			strategyStage = strategyStage || stage;
			if(strategyStage !== 'in_progress') {
				percent += 20;
			}

			return percent;
		},

		cloneSection(slug) {
			let sectionData = data.sections[slug];
			if(sectionData) {
				sectionData = JSON.parse(JSON.stringify(sectionData));
				sectionData.id = uniqid();
				sectionData.notes = "";
				sectionData.highlighted = false;
				return sectionData;
			}
		},

		changePage(pageId) {
			if(pageId) {
				const page = data.getCurrentPageFromId(data.pages, pageId);
				if(page && page.title) {
					// toast({
					// 	title: page.title,
					// 	description: "The current page has been changed.",
					// 	status: "info",
					// 	duration: 2000,
					// 	isClosable: true,
					// })
				}
			}
			data.setPage(pageId);
		},

		getCurrentPageFromId(ps, id, depth=0) {
			let found = false;
	
			for(let i = 0; i < ps.length; i++) {
				const p = ps[i];
				if(p) {
					if(p.id === id) {
						found = p;
						break;
					} else {
						if(p.children && p.children.length) {
							found = data.getCurrentPageFromId(p.children, id, depth+1);
						}
					}
				}
			};
	
			if(found) {
				return found;
			}
		},

		getForm(formName, stepName=false) {
			if(stepName) {
				return data.steps[stepName][formName];
			} else {
				return Object.values(data.steps)[data.step][formName];
			}
		},

		setFormData(formName, formData) {
			const stepNames = Object.keys(data.steps);
			for(let i = 0; i < stepNames.length; i++) {
				if(i === data.step) {
					data.steps[stepNames[i]][formName].data = formData;
				}
			}
			data.setSteps(data.steps);
		},

		setCompleted(stepIndex, subStepIndex, completed) {
			const stepName = Object.keys(data.steps)[stepIndex];
			data.steps[stepName][subStepIndex].completed = completed;

			data.checkStepUnlock(stepIndex);

			data.setSteps(data.steps);
			data.reload();
		},

		toggleCompleted(stepName, subStepIndex) {
			data.setCompleted(
				Object.keys(data.steps).indexOf(stepName),
				subStepIndex,
				!data.steps[stepName][subStepIndex].completed
			);
		},

		setDataCompleted(formName, completed, formData) {
			const stepNames = Object.keys(data.steps);
			let stepShouldUnlock = false;
			for(let i = 0; i < stepNames.length; i++) {
				const stepName = stepNames[i]
				if(i === data.step) {
					data.steps[stepName][formName].completed = completed;
					data.steps[stepName][formName].data = formData;

					data.checkStepUnlock(i);
				}

				if(i === stepShouldUnlock) {
					data.steps[stepNames[i]].data.locked = false;
				}
			}

			data.setSteps(data.steps);
			data.reload();
		},

		setNote(note) {
			const stepName = Object.keys(data.steps)[data.step];
			data.steps[stepName].data.note = note;
			setSteps(data.steps);
		},

		checkStepUnlock(stepIndex) {
			const stepName = Object.keys(data.steps)[stepIndex];

			let allFormsCompleted = true;
			const allForms = Object.keys(data.steps[stepName]);

			for(let i = 0; i < allForms.length; i++) {
				const formName = allForms[i];
				if(formName !== "data" && !data.steps[stepName][formName].completed) {
					allFormsCompleted = false;
					break;
				}
			}
			
			if(allFormsCompleted) {
				const nextName = Object.keys(data.steps)[stepIndex + 1];
				if(nextName) {
					data.steps[nextName].data.locked = false;
				}
			}	
		},

		calculateEstimate() {
			const pages = data.pages;

			let designTime = 0;
			let developmentTime = 0;

			const addPageValue = (page) => {
				page.sections.forEach(section => {
					designTime += (section.designTime || 0);
					developmentTime += (section.developmentTime || 0)
				});
				
				if(page.children && page.children.length) {
					page.children.forEach(addPageValue);
				}
			}

			pages.forEach(addPageValue);

			return {
				design: {
					time: designTime,
					cost: designTime * data.hourlyRate
				},
				development: {
					time: developmentTime,
					cost: developmentTime * data.hourlyRate
				},
			}
		},

		reload() {
			forceUpdate(Date.now());
		}
	};

	// useInterval(() => {
	// 	data.heartbeat(loggedIn);
	// }, 5000);
	if(!data.clientData) {
		data.heartbeat(loggedIn);
	}

	useEffect(() => {
		setLoading(false);
	}, []);

	if(loading || (data.loggedIn && !clientData)) {
		return <FullscreenLoader />;
	} else if(!data.loggedIn && !loading && !clientData) {
		return <LoginForm {...data} />
	} else if(data.loggedIn && !loading) {
		if(!strategy && clientData) {
			return <StrategySelect {...data}/>
		} else {
			return <StrategySession {...data} />
		}
	}
}

export default IndexPage