import React from "react";
import PropTypes from "prop-types";

import { Image } from "@chakra-ui/react";

const propTypes = {
  nodeData: PropTypes.object.isRequired
};

const PageTreeViewNode = (props) => {
	const { nodeData, setPage } = props;

	const selectNode = () => {
		if(setPage) {
			setPage(nodeData.id);
		}
	};

	return (
		<div onClick={selectNode}>
			<div className="page-name">{nodeData.title}</div>
			<div className="sections">
				{nodeData.sections && nodeData.sections.map(section => {
					return (
						<Image key={section.id + "-tree-node"} src={section.image.sourceUrl} />
					);
				})}
			</div>
		</div>
	);
};

PageTreeViewNode.propTypes = propTypes;

export default PageTreeViewNode;