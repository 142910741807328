import React, { useState, useEffect } from "react";

import {
    Button,
    Image,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    Text,
    Box,
    Icon,
    Heading,
    Spinner
} from "@chakra-ui/react";

import { AddIcon, RepeatIcon, LockIcon, UnlockIcon } from "@chakra-ui/icons";

import fetch from 'cross-fetch';


const Slickplan_Modal = (props) => {

    const {isOpen, onClose, refreshToken, details, setDetails, buildURL} = props;
    
    const [sitemaps, setSitemaps] = useState(false);
    const [loadingSitemaps, setLoadingSitemaps] = useState(false);

    const [structure, setStructure] = useState(false);
    const [loadingStructure, setLoadingStructure] = useState(false);

    const getSitemaps = async () => {
        if(!sitemaps) {
            const url = buildURL("sitemaps", {
                access_token: details.access_token
            });
            const req = await fetch(url);
            const res = await req.json();
    
            return res;
        }
    }

    const handleLoadSitemaps = () => {
        setLoadingSitemaps(true);
        setSitemaps([]);
        getSitemaps().then(res => {
            setLoadingSitemaps(false);
            setSitemaps(res);
        });
    }

    const getStructure = async (sitemapId) => {
        const url = buildURL(`sitemaps/${sitemapId}/structure`, {
            access_token: details.access_token
        });
        const req = await fetch(url);
        const res = await req.json();

        console.log(res);

        return res;
    }

    const handleLoadStructure = () => {
        setLoadingStructure(true);
        getStructure(details.sitemap.id).then(res => {
            setLoadingStructure(false);
            setStructure(res);
        })
    }

    if(isOpen && !sitemaps && !loadingSitemaps) {
        handleLoadSitemaps();
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="3xl">
            <ModalOverlay />
            <ModalContent>
            <ModalHeader>Slickplan Integration</ModalHeader>
            <ModalCloseButton />
            <ModalBody>

                {/* Has sitemap selected */}
                {details.sitemap && (
                <Box>
                    <Table variant="simple" size="sm" borderWidth={1}>
                        <Thead>
                            <Tr>
                                <Th>ID</Th>
                                <Th>Title</Th>
                                <Th isNumeric>Select</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            <Tr>
                                <Td>{details.sitemap.id}</Td>
                                <Td>{details.sitemap.title}</Td>
                                <Td isNumeric>
                                    <Button
                                        colorScheme="red"
                                        size="sm"
                                        onClick={() => {
                                            setDetails({ sitemap: undefined }, () => {
                                                setStructure(false);
                                            });
                                        }}
                                        rightIcon={<Icon as={UnlockIcon} />}
                                    >Unlink</Button>
                                </Td>
                            </Tr>
                        </Tbody>
                    </Table>
                </Box>
                )}

                {/* Does not have sitemap selected */}
                {!details.sitemap && (
                <Box>
                    <Box marginBottom={5} textAlign="center">
                        <Heading size="sm" mb={10}>Please select a Slickplan sitemap to associate with this strategy.</Heading>
                    </Box>
                    {(!sitemaps || sitemaps.length < 1) && loadingSitemaps && (
                        <Box textAlign="center">
                            <Text display="inline" verticalAlign="middle">Loading Sitemaps...&nbsp;</Text>
                            <Spinner verticalAlign="middle" color="blue.500" />
                        </Box>
                    )}
                    {sitemaps && !loadingSitemaps && <>
                        <Box marginBottom={5} textAlign="center">
                            <Text display="inline" marginRight={3}>
                                If a sitemap doesn't exist on Slickplan yet
                                </Text>&nbsp;
                            <Button colorScheme="blue" size="sm" rightIcon={<Icon as={AddIcon} />}>Create New</Button>
                        </Box>
                        <Box textAlign="left">
                            <Button
                                size="sm"
                                rightIcon={<Icon as={RepeatIcon} />}
                                borderBottomRadius={0}
                                onClick={handleLoadSitemaps}
                            >Refresh</Button>
                        </Box>
                    </>}
                    {sitemaps && sitemaps.length > 0 && !loadingSitemaps && <>
                        <Table variant="simple" size="sm" borderWidth={1}>
                            <Thead>
                                <Tr>
                                    <Th>ID</Th>
                                    <Th>Title</Th>
                                    <Th isNumeric>Select</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {sitemaps.sort((a, b) => b.id - a.id).map(sitemap => 
                                    <Tr key={"sitemap-" + sitemap.id}>
                                        <Td>{sitemap.id}</Td>
                                        <Td>{sitemap.title}</Td>
                                        <Td isNumeric>
                                            <Button
                                                colorScheme="green"
                                                size="sm"
                                                onClick={() => {
                                                    setDetails({ sitemap }, () => {
                                                        handleLoadStructure();
                                                    })
                                                }}
                                                rightIcon={<Icon as={LockIcon} />}
                                            >Link</Button>
                                        </Td>
                                    </Tr>
                                )}
                            </Tbody>
                            <Tfoot>
                                <Tr>
                                    <Th>ID</Th>
                                    <Th>Title</Th>
                                    <Th isNumeric>Select</Th>
                                </Tr>
                            </Tfoot>
                        </Table>
                    </>}
                </Box>
                )}

            </ModalBody>

            <ModalFooter>
                <Button colorScheme="blue" mr={3} onClick={onClose}>
                Close
                </Button>
            </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export default Slickplan_Modal;