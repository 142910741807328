import React from 'react';

import {
	Container,
	Image,
	List,
	ListItem,
	Button,
	IconButton,
	HStack,
	Center,
	Heading,
	Divider,
	Box,
	Flex,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    BreadcrumbSeparator,
} from "@chakra-ui/react";

import { ChevronRightIcon } from '@chakra-ui/icons';

const PageBreadcrumbs = (props) => {
    const { pages, page, changePage } = props;
    
    const getBreadcrumbs = (tree, treeId) => {
        const result = [];
        const traverse = (thisTree, thisId) => {
            for(let i = 0; i < thisTree.children.length; i++) {
                const node = thisTree.children[i];
                if(node.id === thisId) {
                    result.unshift({id: node.id, title: node.title, current: false});
                    traverse(tree, thisTree.id);
                } else if(node.children && node.children.length) {
                    traverse(node, thisId);
                }
            }
        }

        traverse(tree, treeId);
        result[result.length - 1].current = true;

        return result;
    }

    const crumbs = getBreadcrumbs({id: "root", children: pages}, page);

    return (
    <Breadcrumb spacing="8px" separator={<ChevronRightIcon color="gray.500" />} pl={2}>
        {crumbs.map(crumb => {

            return (
            <BreadcrumbItem key={"breadcrumb-" + crumb.id} isCurrentPage={crumb.current}>
                <BreadcrumbLink onClick={(e) => {
                    if(!crumb.current) {
                        e.preventDefault();
                        changePage(crumb.id)
                    }
                }}
                href="#"
                color={crumb.current && "purple.500"}
                textDecoration={!crumb.current && 'underline'}
                cursor={crumb.current && 'default'}
                fontWeight={crumb.current && 'bold'}
                _hover={{
                    textDecoration: 'none'
                }}
                >
                {crumb.title}
                </BreadcrumbLink>
            </BreadcrumbItem>
            );

        })}
    </Breadcrumb>
    );
}

export default PageBreadcrumbs;