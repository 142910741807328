import React from 'react';

import OrganizationChart from "@dabeng/react-orgchart";
import PageTreeViewNode from "./PageTreeViewNode";

const PageTreeView = (props) => {
    const { pages, name, changePage, onClose } = props;

    const treeData = {
        id: "root-page",
        title: name,
        children: pages
    };

    return (
        <>
        <OrganizationChart
            chartClass={"myChart"}
            datasource={treeData}
            pan={false}
            zoom={false}
            zoomoutLimit={0.7}
            zoominLimit={3}
            collapsible={false}
            NodeTemplate={(nodeProps) => {
                return (
                <PageTreeViewNode
                    setPage={changePage ? pageId => {
                        if(onClose) {
                            onClose();
                        }
                        changePage(pageId);
                    } : false}
                    {...nodeProps}
                >
                </PageTreeViewNode>
                );
            }}
        />
        </>
    )
}




export default PageTreeView;