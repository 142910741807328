import React from 'react';

import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    useDisclosure
} from "@chakra-ui/react"

import { ViewIcon } from '@chakra-ui/icons';

import PageTreeView from "./PageTreeView.jsx";

const PageTreeViewPopup = (props) => {
    const { pages, strategyName, changePage } = props;
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <>

            {pages.length > 0 && <Button size={"sm"} onClick={onOpen} rightIcon={<ViewIcon />}>View full sitemap</Button>}

            <Modal
                size={"full"}
                isOpen={isOpen}
                onClose={onClose}
                isCentered={false}
                scrollBehavior={"inside"}
            >
                <ModalOverlay />
                <ModalContent marginTop={0}>
                    <ModalHeader>Page Tree View</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <PageTreeView name={strategyName} pages={pages} changePage={changePage} onClose={onClose}/>
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={onClose}>
                            Close
                        </Button>
                        <Button variant="ghost">Secondary Action</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}




export default PageTreeViewPopup;