import React, { useState } from "react";

import FocusLock from "react-focus-lock";

import {
	IconButton,
	Tooltip,
	Input,
	Button,
	Popover,
	PopoverTrigger,
	PopoverContent,
	PopoverHeader,
	PopoverBody,
	PopoverFooter,
	PopoverArrow,
	Portal,
    RadioGroup,
    Radio,
    Grid,
    HStack,
    Box,
    Text,
    useRadioGroup,
    useRadio
} from "@chakra-ui/react";

import { BiPurchaseTag } from "react-icons/bi";

const ColorCard = (props) => {
    const { getInputProps, getCheckboxProps } = useRadio(props)
  
    const input = getInputProps();
    const checkbox = getCheckboxProps();
  
    return (
      <Box as="label">
        <input {...input} />
        <Box
            {...checkbox}
            cursor="pointer"
            w={8}
            h={8}
            margin={"auto"}
            borderWidth="1px"
            borderRadius="full"
            boxShadow="md"
            bg={props.value + ".500"}
            onClick={props.onClick}
            _hover={{
                bg: props.value + ".300"
            }}
            _checked={{
                borderWidth: 10,
                bg: "white",
                borderColor: props.value + ".500",
                boxShadow: "inner"
            }}
            _focus={{
                boxShadow: "outline",
                outlineWidth: 0,
            }}
        >
          {props.children}
        </Box>
      </Box>
    )
}

const TagPopover = (props) => {
    const { addTag, pageId } = props;
    const [color, setColor] = useState("red");
    const [label, setLabel] = useState("");
    const colors = ["red", "orange", "yellow", "green", "teal", "cyan", "blue", "purple", "pink"];
    const firstFieldRef = React.useRef(null);

    const [isOpen, setIsOpen] = React.useState(false)
    const open = () => setIsOpen(!isOpen);
    const close = () => setIsOpen(false);

    const { getRootProps, getRadioProps } = useRadioGroup({
        name: "color-select",
        defaultValue: "red",
        onChange: val => {
            setColor(val);
        },
    });

    const handleAddTag = () => {
        setLabel("");
        addTag(pageId, label, color);
        close();
    }

    
    const group = getRootProps()

    return (
        <Popover
            autoFocus={true}
            isOpen={isOpen}
            onOpen={open}
            onClose={close}
            initialFocusRef={firstFieldRef}
        >
            <PopoverTrigger>
                {/* <Text opacity={0} ml={-7} pointerEvents="none">-</Text> */}
                <IconButton
                    bg={"transparent"}
                    aria-label="Tag Page"
                    size="xs"
                    icon={<BiPurchaseTag />}
                    // onClick={() => setShowTagInput(true)}
                    m="2px"
                />
            </PopoverTrigger>
            <Portal outline={"none"} borderWidth={10}>
                <PopoverContent width="150px">
                    <FocusLock returnFocus persistentFocus={false}>
                        <PopoverArrow />
                        <PopoverHeader borderBottomWidth={0}>
                            <Input
                                value={label}
                                placeholder="Tag label..."
                                ref={firstFieldRef}
                                autoComplete="off"
                                size={"sm"}
                                onChange={e => setLabel(e.target.value)}
                                onKeyDown={e => e.key === 'Enter' && handleAddTag()}
                                data-lpignore="true"
                                data-form-type="text"
                            />
                        </PopoverHeader>
                        <PopoverBody>
                            <Grid
                                {...group}
                                templateColumns="repeat(3, 1fr)"
                                gap={2}
                            >
                            {colors.map((value) => {
                                const radio = getRadioProps({ value })
                                return (
                                <ColorCard
                                    onClick={() => {
                                        setTimeout(() => {
                                            firstFieldRef.current.focus()
                                        });
                                    }}
                                    key={value}
                                    {...radio}>
                                </ColorCard>
                                )
                            })}
                            </Grid>
                        </PopoverBody>
                        <PopoverFooter textAlign="right">
                            <Button size={"xs"} colorScheme="purple" onClick={handleAddTag}>Add Tag</Button>
                        </PopoverFooter>
                    </FocusLock>
                </PopoverContent>
            </Portal>
        </Popover>
    );
}

export default TagPopover;