import React from 'react';

import { Text } from "@chakra-ui/react";

const HighlightResult = (props) => {
    let keyword = props.keyword || "";
    keyword = keyword.replace(/[^\w\s]/gi, '')

    const realProps = {...props};
    delete realProps.keyword;

    const highlightProps = {...(realProps._highlight || {})};
    delete realProps._highlight;

    
    const parts = (props.children || "").split(new RegExp(`(${keyword})`, 'gi'));
    return <>
    {
    parts.map((part, i) => {
        const matches = part.toLowerCase() === keyword.toLowerCase();
        return (
            <Text
                {...realProps}
                as="span"
                d="inline"
                key={i}
                {...(matches ? highlightProps : {})}

            >
                { part }
            </Text>
        );
    }
    )}
    </>;
}

export default HighlightResult;