import React, { useState } from "react";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Icon,
    Text,
    Divider,
    Textarea,
    ButtonGroup,
    Box,
	Accordion,
	AccordionItem,
	AccordionButton,
	AccordionPanel,
	AccordionIcon,
    useDisclosure
} from "@chakra-ui/react"

import { useApolloClient } from '@apollo/client';
import { SAVE_STRATEGY_FEEDBACK } from '../apollo/queries';

import { MdFeedback, MdQuestionAnswer, MdCheckCircle, MdSend } from "react-icons/md"; 

import { ViewIcon } from "@chakra-ui/icons";

import FeedbackBox from "./FeedbackBox";

const ActionFeedback = (props) => {

	const { feedback, setFeedback, save, hasUnactioned, databaseId } = props;

	const [isSaving, setIsSaving] = useState(false);

    const { isOpen, onOpen, onClose } = useDisclosure()
    const finalRef = React.useRef();

	const client = useApolloClient();

    const saveFeedback = (feedbackId, feedbackData) => {
		const tempFeedback = feedback.map(feedbackItem => {
			if(feedbackItem.id === feedbackId) {
				feedbackItem = { ...feedbackItem, ...feedbackData };
			}

			return feedbackItem;
		});

		setFeedback(tempFeedback);
    }

	const handleSave = () => {
		let tempFeedback = JSON.parse(JSON.stringify(feedback));
		for(let i = 0; i < tempFeedback.length; i++) {
			tempFeedback[i].actioned = tempFeedback[i].feedback || tempFeedback[i].complete;
		}
		setFeedback(tempFeedback)
		setIsSaving(true);

        client.mutate({
            mutation: SAVE_STRATEGY_FEEDBACK,
            variables: {
                id: props.databaseId,
                feedback: JSON.stringify(tempFeedback)
            }
        }).then((res) => {
            setIsSaving(false);
			onClose();
        }).catch(e => {
            console.log(e);
            setIsSaving(false);
        });
	}
  
    return (
		<>  
			{<Button
				mt={4}
				size="md"
				rightIcon={<Icon as={hasUnactioned ? MdFeedback : ViewIcon} />}
				colorScheme={hasUnactioned ? "red" : "gray"}
				onClick={onOpen}
			>{hasUnactioned ? "Action Feedback" : "View Feedback"}</Button>}
			<Modal finalFocusRef={finalRef} isOpen={isOpen} onClose={onClose} size="2xl">
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Action Feedback</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Accordion allowMultiple>
							{feedback.length > 0 && feedback.map(feedbackItem => <FeedbackBox isAccordion={true} key={"feedback-" + feedbackItem.id} {...feedbackItem} save={saveFeedback}/>)}
						</Accordion>

					</ModalBody>
		
					<ModalFooter>
						<Button mr={2}>Come back later</Button>
						<Button colorScheme="purple" rightIcon={<MdSend />} isLoading={isSaving} onClick={handleSave}>Save for client</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
    )
}

export default ActionFeedback;