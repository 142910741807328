import React from 'react';

import {
    Image,
    Box,
    Text,
    Tooltip,
} from "@chakra-ui/react";

import HighlightResult from "./HighlightResult";

const SectionCard = (props) => {
	const {
        section,
		selectedSection,
        setSelectedSection,
        searchInput
	} = props;

	const handleSelectTemplate = (slug) => {
		const toThis = selectedSection === slug ? false : slug;
		setSelectedSection(toThis);
	}

    const isSelected = selectedSection === section.slug;

    return (
    <Tooltip label={section.description} placement="left" key={"section-select-" + section.id}>
        <Box
        className="section"
        cursor="pointer"
        p={2}
        // borderWidth={1}
        _hover={!isSelected ? {
            bg: "gray.100"
        } : {}}
        bg={isSelected ? "purple.500" : "white"}
        color={isSelected ? "white" : "black"}

        onClick={() => handleSelectTemplate(section.slug)}
        >
            <HighlightResult
                fontSize={12}
                fontWeight={"bold"}
                marginBottom={2}
                keyword={searchInput}
                _highlight={{
                    "bg": "blue.100",
                }}
            >{section.title}</HighlightResult>
            <Image
                w={"100%"}
                src={section.image.sourceUrl}
                alt={section.title}
                ignoreFallback={true}
            ></Image>
        </Box>
    </Tooltip>
    );
}

export default SectionCard;