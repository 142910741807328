import React, { useState, useEffect } from 'react';
import {
    DeleteIcon,
    SettingsIcon,
    DragHandleIcon,
    EditIcon,
    ViewIcon
} from '@chakra-ui/icons'

// ----temporarily removed------   @TODO Add back in and fix performance issue
//
// import NotesPopover from "./NotesPopover";
// import SettingsPopover from "./SettingsPopover";

import {
	Image,
	IconButton,
	HStack,
    ScaleFade,
    Center,
    Text,
    Stack,
    Box,
    Icon,
    Heading
} from "@chakra-ui/react";

import { WarningIcon } from "@chakra-ui/icons";

import InsertSection from "./InsertSection";



const SectionPreview = (props) => {
    const {
        index,
        section,
        selectedSectionData,
        selectedSection,
        handleNewSection,
        deleteSection,
        duration,
        hoveringSection,
        setHoveringSection,
        setSelectedPreview,
    } = props;

    const [isShowing, setShow] = useState(false);

    useEffect(() => {
        setShow(true);
        return () => setShow(false);
    }, []);

    const handleDeleteSection = (sectionId) => {
        setShow(false);
        setTimeout(() => {
            deleteSection(sectionId);
        }, 300);
    }

    const hovering = hoveringSection && section.id === hoveringSection.id;

    return (
        <ScaleFade
            in={isShowing}
            transition={{ enter: {duration: 0.3}, exit: {duration: 0.3} }}
            onHoverStart={() => {
                setHoveringSection(section);
            }}
            onHoverEnd={() => {
                setHoveringSection(false);
            }}
        >
        {index===0 && (
                <InsertSection 
                index={0}
                sectionData={selectedSectionData}
                selectedSection={selectedSection}
                handleNewSection={handleNewSection}
                />
            )}

            {(!selectedSection) && <Center
                width="100%"
                height="100%"
                position="absolute"
                bg={"blackAlpha.400"}
                transition="all 0.1s"
                opacity={hovering ? 1 : 0}
                pointerEvents={hovering ? "all" : "none"}
                cursor={"move"}
            >
                <HStack p={2} bg={"white"} borderRadius={10}>
                    <IconButton
                        colorScheme={"blue"}
                        size={"sm"}
                        icon={<EditIcon />}
                        onClick={() => setSelectedPreview(section)}
                    ></IconButton>
                    <Text size={14} marginLeft={30} marginRight={30}>
                        <Icon as={DragHandleIcon} />
                        <Text as="span" fontSize={12} marginLeft={10} marginRight={10}>Drag to reorder</Text>
                        <Icon as={DragHandleIcon} />
                    </Text>
                    <IconButton
                        colorScheme={"red"}
                        size={"sm"}
                        icon={<DeleteIcon />}
                        onClick={() => handleDeleteSection(section.id)}
                    ></IconButton>
                </HStack>
            </Center>}

            <Image
                src={section.image.sourceUrl || section.image}
                alt={`Section ${section.id} Image`}
            ></Image>

            {section.highlighted && (
            <Box position="absolute" right={5} top={3} cursor={"move"}>
                <WarningIcon fontSize={"24px"} color="red.400"/>
            </Box>
            )}

            <InsertSection
                index={index + 1}
                sectionData={selectedSectionData}
                selectedSection={selectedSection}
                handleNewSection={handleNewSection}
            />
        </ScaleFade>
    );
}

export default SectionPreview;