import React from 'react';

import FormContainer from "../FormContainer";

import TextInputList from "../TextInputList";

import {
    Grid,
    Box,
} from "@chakra-ui/react";

import SVG from "../SVG";

import Icon1 from "../../images/illustrations/designer-working-5.svg";
import Icon2 from "../../images/illustrations/social-media-discussion-4.svg";

const DesignSocialAssets = (props) => {
    const formName = "Design & Social Media Assets";
    const globalForm = props.getForm(formName);

    const formData = globalForm.data || {
        designAssets: [],
        socialMedia: []
    };

    const updateFormData = (dataKey, dataVals) => {
        formData[dataKey] = dataVals;
        props.setDataCompleted(
            formName,
            Object.values(formData).filter(_=>_.length).length === Object.keys(formData).length,
            formData
        );
        props.reload();
    }
    
    return (
        <FormContainer
        formName={formName}
            //subtext={"What do we want people to do on the site in order to measure success?"}
        {...props}
        >

            <Grid templateColumns={"1fr 2fr"}>
                <Box>
                    <SVG src={Icon1} width="100%"/>
                </Box>
                <Box paddingLeft={10}>
                    <TextInputList
                        {...props}
                        heading={"Design assets"}
                        text={"How will we use the existing/new assets of the brand in the design of the website?"}
                        btnText={"Add asset"}
                        data={formData.designAssets}
                        onChange={(dataVals) => updateFormData("designAssets", dataVals)}
                    />
                </Box>
            </Grid>

            <Grid templateColumns={"1fr 2fr"}>
                <Box>
                    <SVG src={Icon2} width="100%"/>
                </Box>
                <Box paddingLeft={10}>
                    <TextInputList
                        {...props}
                        heading={"Social media"}
                        text={"Where and how do you see social media being incorporated into the site?"}
                        btnText={"Add item"}
                        data={formData.socialMedia}
                        onChange={(dataVals) => updateFormData("socialMedia", dataVals)}
                    />
                </Box>
            </Grid>

        </FormContainer>
    );
}

export default DesignSocialAssets;