import React from 'react';

import FormContainer from "../FormContainer";

import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Link,
    Tooltip,
    Button,
    Flex,
    Spacer,
    Box,
    Center,
    Alert,
    AlertIcon,
} from "@chakra-ui/react";

import { TriangleUpIcon } from "@chakra-ui/icons";

const WebsiteShouldSay = (props) => {

    const { getForm, setFormData, setStep, step, setCompleted } = props;

    const formName = "What do we want the website to say?";
    const globalForm = getForm(formName);
    
    const ps = {
        "H": ["High", "red"],
        "M": ["Medium", "orange"],
        "L": ["Low", "green"]
    };

    let userNeeds = getForm("User Needs", "User Objectives")?.data?.needs;

    let formData = globalForm.data || (userNeeds && userNeeds.map(u => {
        u.priority = "";
        u.name = u.value || u.text;
        return u;
    })) || [

    ];

    const sortByPriority = () => {
        formData = formData.sort((a, b) => Object.keys(ps).indexOf(a.priority) - Object.keys(ps).indexOf(b.priority));
        setFormData(formName, formData);
        props.reload();
    }

    const setPriority = (moduleName, priority) => {
        let emptyCount = 0;
        for(let i = 0; i < formData.length; i++) {
            if(formData[i].name === moduleName) {
                formData[i].priority = priority;
            }

            if(formData[i].priority === "") {
                emptyCount++;
            }
        }        
        setFormData(formName, formData);

        if(emptyCount === 0) {
            setCompleted(step, formName, true);
        } else {
            setCompleted(step, formName, false);
        }
    }

    return (
        <FormContainer
            formName={formName}
            {...props}
        >
            {formData.length < 1 && (
            <Alert status="warning">
                <AlertIcon />
                Please complete the <Button size={"sm"} pl={1} pr={1} variant={"link"} onClick={() => setStep(2)}>User Objectives</Button> step to begin this one.
            </Alert>
            )}
            {formData.length > 0 && (
            <Box>
                <Table size="sm" borderWidth={1}>
                    <Thead>
                        <Tr>
                            <Th>Information module</Th>
                            <Th isNumeric>Priority</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {(
                            formData
                                .map((module, i) => {
                                return (
                                    <Tr key={"module-" + i}>
                                        <Td>{module.name}</Td>
                                        <Td fontWeight={"bold"} fontSize={14} isNumeric>
                                        {
                                            Object.keys(ps).map((pk, j) => (
                                                <React.Fragment key={"ps-" + j}>
                                                    {(j !== 0) && (
                                                        <>
                                                        &nbsp;
                                                        &nbsp;
                                                        </>
                                                    )}
                                                    <Tooltip hasArrow label={ps[pk][0]} bg={ps[pk][1]} placement="top">
                                                        <Link href={"#"} onClick={() => setPriority(module.name, pk)} color={module.priority === pk ? ps[pk][1] : "#f2f2f2"}>{pk}</Link>
                                                    </Tooltip>
                                                </React.Fragment>
                                            ))
                                        }
                                        </Td>
                                    </Tr>
                                );
                            })
                        )}
                    </Tbody>
                </Table>
                <Flex>
                    <Spacer />
                    <Button
                        onClick={() => sortByPriority()}
                        rightIcon={<TriangleUpIcon />}
                        colorScheme="purple"
                        minW="100px"
                        borderTopRadius={0}
                        size={"sm"}
                    >
                        Sort
                    </Button>
                </Flex>
            </Box>
            )}
        </FormContainer>
    );
}

export default WebsiteShouldSay;