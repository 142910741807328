import React, { useState, useEffect } from 'react';

import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { USER_STRATEGIES, ALL_STRATEGIES, CREATE_STRATEGY, STRATEGY_BY_ID } from '../apollo/queries.js';

import { globalData } from "../lib/GlobalState.js";

import {
    Flex,
    Box,
    Center,
    CircularProgress,
    Stack,
    Heading,
    Wrap,
    Text,
    Icon,
} from "@chakra-ui/react";

import Header from "../components/Header.jsx";
import StrategyCard from "./StrategyCard.jsx";
import CreateStrategy from "./CreateStrategy.jsx";
import strategyStages from "../lib/StrategyStages";

const StrategySelect = (props) => {
    const { loggedIn, isAdmin, importData,  setStrategy, strategy, save, duration } = props;

    const [isShowing, setShow] = useState(false);
    const [showingNamePopup, setShowingNamePopup] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const defaultStrategy = JSON.stringify(globalData);

    const authorId = +(loggedIn?.data?.user?.id);

    const strategiesResult = useQuery(isAdmin() ? ALL_STRATEGIES : USER_STRATEGIES, {
        variables: { authorId }
    });

    const [createStrategy, create] = useMutation(CREATE_STRATEGY, {
        onError: () => null,
        onCompleted: (data) => {
            importData({
                id: data.createStrategy.strategy.id,
                name: data.createStrategy.strategy.title,
                session: defaultStrategy,
            });

            save();
        }
    });

    const [loadStrategy] = useLazyQuery(STRATEGY_BY_ID, {
        fetchPolicy: "network-only",
        onError: (e) => {
            console.log(e);
        },
        onCompleted: (data) => {
            const strategy = data.strategyBy;
            setIsLoading(false);
            importData({
                id: strategy.id,
                name: strategy.title,
                feedback: strategy.strategyData?.feedback || [],
                stage: strategy.strategyData?.strategyStage,
                session: strategy.strategyData?.sessionData || defaultStrategy,
                client: strategy.strategyData?.client?.client
            });
        }
    })

	const strategies = strategiesResult.data?.strategies.nodes.map(s => {
		s = {
			...s,
			...s.strategyData
		}

		delete s.strategyData;
		return s;
	});

    if(strategies) {
        for(const stageName in strategyStages) {
            strategyStages[stageName].strategies = [];
        }
        strategies.forEach(s => {
            strategyStages[s.strategyStage || "in_progress"].strategies.push(s);
        })
    }

    const handleCreate = (title) => {
        title = title.trim() || "Unnamed Strategy";
        createStrategy({variables: { title }});
    }

    const handleLoadStrategy = (strategy) => {
        loadStrategy({variables: { id: strategy.id }});
        setIsLoading(strategy.id);
    }

    useEffect(() => {
        setShow(true);
        return () => setShow(false)
    }, []);

    if(!props.strategy) {
        return (
            <Box height="100vh">
                <Header {...props} createStrategy={handleCreate} createLoading={create.loading}/>
                <Flex height="calc(100vh - 60px)" overflowY={"auto"} p={20} pt={0}>
                    {strategiesResult.loading && (
                        <Center h="100%" w={"100%"}>
                            Loading {isAdmin() ? "Strategies" : "Strategy"} &nbsp;&nbsp;
                            <CircularProgress isIndeterminate color="purple.300" />
                        </Center>
                    )}
                    {strategies && isAdmin() && (
                    <Stack>
                        {
                            Object.keys(strategyStages).map(strategyStage => {
                                const stage = strategyStages[strategyStage];
                                stage.label = strategyStage;
                                stage.scheme = "purple";
                                return (
                                    <Box key={stage.heading} borderBottom="2px solid #edf2f7" mt="30px" pt="30px">
                                        <Heading size="lg" marginBottom={2}>
                                            <Icon as={stage.icon} mr={4} verticalAlign="middle"/>
                                            <Text as="span" verticalAlign="middle">{stage.heading} ({stage.strategies.length})</Text>
                                        </Heading>
                                        <Box paddingTop={3} paddingBottom={3} marginBottom={10}>
                                        {stage.strategies.length > 0 && (
                                            <Wrap spacing={10}>
                                            {
                                                stage.strategies.map(strategy => (
                                                    <StrategyCard
                                                        key={"strategy-card-" + strategy.id}
                                                        strategy={strategy}
                                                        loadStrategy={handleLoadStrategy}
                                                        isLoading={isLoading}
                                                        duration={duration}
                                                        isAdmin={isAdmin()}
                                                        stage={stage}
                                                        refetch={() => strategiesResult.refetch()}
                                                    />
                                                )
                                            )}
                                            </Wrap>
                                        )}
                                        {stage.strategies.length < 1 && (
                                            <Box>
                                                No strategies at this stage
                                            </Box>
                                        )}
                                        </Box>
                                    </Box>
                                );
            
                            })
                        }
                    </Stack>
                    )}
                    {strategies && !isAdmin() && (
                        <Center height="100%" width="100%">
                            {strategies.length > 0 && (
                                <StrategyCard
                                    key={"strategy-card-" + strategy.id}
                                    strategy={strategies[0]}
                                    loadStrategy={handleLoadStrategy}
                                    isLoading={isLoading}
                                    duration={duration}
                                />
                            )}
                            {strategies.length < 1 && (
                                <Box>
                                    <Stack>
                                        <Text>You have not started a strategy yet.</Text>
                                        <CreateStrategy createStrategy={handleCreate} createLoading={create.loading}/>
                                    </Stack>
                                </Box>
                            )}
                        </Center>
                    )}
                </Flex>
            </Box>
        );
    } else {
        return <></>;
    }
}

export default StrategySelect;