import React, { useState, useEffect } from "react";

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import {
    Box,
    Flex,
    SlideFade,
    Button
} from "@chakra-ui/react";

import uniqid from 'uniqid';

import { useLazyQuery } from '@apollo/client';
import { TEMPLATE_BY_ID } from "../apollo/queries.js";

import RenderPreview from "../components/RenderPreview.jsx";
import SectionSelect from "../components/SectionSelect.jsx";
import SectionPreviewEdit from "../components/SectionPreviewEdit.jsx";

import { walk } from "react-sortable-tree";

const blankPage = {
	title: "Blank Page",
	sections: [],
	children: [
  
	],
};

const PageBuilder = (props) => {

    const {
        pages,
        setPages,
        page,
        changePage,
        getCurrentPageFromId,
        duration,
        sections,
		setSections,
		selectedSection,
		setSelectedSection,
		cloneSection,
        reload,
        save
    } = props;

    const [isShowing, setShow] = useState(false);

    const [loadedTemplates, setLoadedTemplates] = useState({});

    useEffect(() => {
        setShow(true);
        return () => setShow(false);
    }, []);

    const [selectedPreview, setSelectedPreviewHandler] = useState(false);

    const addNewPage = (pageTitle, sections) => {
		const newPage = JSON.parse(JSON.stringify(blankPage));
		newPage.id = uniqid();
		newPage.title = pageTitle || "Unnamed Page";
		newPage.sections = sections;
  
        pages.push(newPage);
        console.log(pages);
		setPages(pages);
    }

    const handlePageFromTemplate = (templateId) => {
        const data = loadedTemplates[templateId].data;

        const templateStructure = data.wireframeTemplate?.wireframeTemplateData?.templateStructure;
        if(templateStructure && templateStructure.length) {
            const sections = templateStructure.map(sectionData => {
                return cloneSection(sectionData.section[0].slug);
            });
            
            // addNewPage(data.title, sections);
        }
    }

    const [getTemplate] = useLazyQuery(TEMPLATE_BY_ID, {
        onCompleted: (data) => {
            const id = data.wireframeTemplate.id;
            loadedTemplates[id].data = data;
            handlePageFromTemplate(id);
        }
    });

	const pageData = getCurrentPageFromId(pages, page);

    const updatePageSections = sections => {
        const setSections = (ps) => {
            for(let i = 0; i < ps.length; i++) {
                if(ps[i].id === page) {
                    ps[i].sections = sections;
                } else if(ps[i].children) {
                    setSections(ps[i].children);
                }
            }
        }

        setSections(pages);
		setPages(pages);
	}

    const setSelectedPreview = (section) => {
        setSelectedPreviewHandler(section);
    }

    const handleOnDragEnd = (result) => {
        if (!result.destination) return;
        const items = Array.from(pageData.sections);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        updatePageSections(items);
    }

	const deleteSection = (id) => {
		const setSections = (ps) => {
			for(let i = 0; i < ps.length; i++) {
				if(ps[i].id === page) {
					ps[i].sections = ps[i].sections.filter(section => {
						return section.id !== id;
					})
				} else if(ps[i].children) {
					setSections(ps[i].children);
				}
			}
		}

		setSections(pages);
		setPages(pages);
        reload();
	}

	const handleNewSection = (index) => {
		const section = cloneSection(selectedSection);
		const setSections = (ps) => {
			for(let i = 0; i < ps.length; i++) {
				if(ps[i].id === page) {
					ps[i].sections.splice(index, 0, section);
				} else if(ps[i].children) {
					setSections(ps[i].children);
				}
			}
		}

		setSections(pages);
		setPages(pages);
		setSelectedSection(false);
        
        if(section.hasRequiredPages && section.requiredPages && section.requiredPages.length) {
            section.requiredPages.forEach(page => {
                if(page.pageTemplate) {
                    if(loadedTemplates[page.pageTemplate.id] && loadedTemplates[page.pageTemplate.id].data) {
                        handlePageFromTemplate(page.pageTemplate.id);
                    } else {
                        loadedTemplates[page.pageTemplate.id] = {title: page.pageName, data: false};
                        getTemplate({
                            variables: {
                                id: page.pageTemplate.id
                            }
                        });
                    }
                }
            });
        }

        // save(false);
	}

    const updateSection = (sectionId, updatedData={}) => {
        const setUpdates = (ps) => {
            for(let i = 0; i < ps.length; i++) {
                if(ps[i].id === page) {
                    for(let j = 0; j < ps[i].sections.length; j++) {
						if(ps[i].sections[j].id === sectionId) {
                            for(const property in updatedData) {
                                ps[i].sections[j][property] = updatedData[property];
                            }
						}
					}
                } else if(ps[i].children) {
                    setUpdates(ps[i].children);
                }
            }
        }

        setUpdates(pages);
		setPages(pages);
    }

	const setSectionNotes = (sectionId, value) => {
        updateSection(sectionId, {
            note: value
        })
	}

    return (
        <React.Fragment>
            
            <Box flex={7}>
                <SlideFade in={isShowing} transition={{ enter: {duration}, exit: {duration} }}>
                    <DragDropContext onDragEnd={handleOnDragEnd}>
                        <Flex height="100%">
                            <Box flex={pageData ? 5 : 7}>
                                <RenderPreview
                                    page={page}
                                    pages={pages}
                                    changePage={changePage}
                                    selectedSection={selectedSection}
                                    sections={sections}
                                    deleteSection={deleteSection}
                                    handleNewSection={handleNewSection}
                                    setSectionNotes={setSectionNotes}
                                    pageData={pageData}
                                    duration={duration}
                                    selectedPreview={selectedPreview}
                                    setSelectedPreview={setSelectedPreview}
                                />
                            </Box>
                            <Box flex={2} display={!pageData && "none"}>
                                <SectionSelect
                                    sections={sections}
                                    setSections={setSections}
                                    selectedSection={selectedSection}
                                    setSelectedSection={setSelectedSection}
                                    page={page}
                                    pages={pages}
                                    setPages={setPages}
                                    getCurrentPageFromId={getCurrentPageFromId}
                                    duration={duration}
                                    cloneSection={cloneSection}
                                />
                            </Box>  
                        </Flex>
                    </DragDropContext>
                </SlideFade>
            </Box>

            <SectionPreviewEdit
                updateSection={updateSection}
                section={selectedPreview}
                setSection={setSelectedPreview}
                sections={sections}
                reload={reload}
            ></SectionPreviewEdit>

        </React.Fragment>
    );
}

export default PageBuilder;