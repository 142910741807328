import React, { useState, useEffect } from "react";

import StepContainer from "../components/StepContainer.jsx";

import {
    Box,
    Center,
    Heading,
    Text,
    List,
    ListItem,
    ListIcon,
    Image,
    Button
} from "@chakra-ui/react";

import { CheckCircleIcon, ArrowForwardIcon } from '@chakra-ui/icons';


const Overview = (props) => {

    return (
        <StepContainer {...props} hidePanel>
            <Center h="100%">
                <Box w="700px" p="120px">
                    <Heading mb="30px">The Navigator Website Essentials</Heading>
                    <Text mb="30px">A professionally built website will help increase sales, maintain a strong image for your brand in the marketplace and instil trust in your customers.</Text>
                    <Text mb="30px">In this session, we're going to navigate through the following pillars to construct your new website strategy.</Text>  
                    <List mb="30px" spacing="10px">
                        <ListItem>
                            <ListIcon as={CheckCircleIcon} color="purple.500"/>
                            Business objectives
                        </ListItem>
                        <ListItem>
                            <ListIcon as={CheckCircleIcon} color="purple.500"/>
                            User objectives
                        </ListItem>
                        <ListItem>
                            <ListIcon as={CheckCircleIcon} color="purple.500"/>
                            Current assets
                        </ListItem>
                        <ListItem>
                            <ListIcon as={CheckCircleIcon} color="purple.500"/>
                            Information and SEO
                        </ListItem>
                        <ListItem>
                            <ListIcon as={CheckCircleIcon} color="purple.500"/>
                            Site content mapping
                        </ListItem>
                    </List>
                    <Button onClick={() => props.setStep(1)} colorScheme="purple" size="lg" rightIcon={<ArrowForwardIcon />}>Continue</Button>
                </Box>
                <Box w="50%" p="120px">
                    <Image w="500px" src="https://strategy.embarkagency.com.au/wp/wp-content/uploads/2021/08/searching-5.svg" alt="Website Navigator" />
                </Box>
            </Center>
        </StepContainer>
    );
}

export default Overview;