import React from 'react';

import FormContainer from "../FormContainer";

import TextInputList from "../TextInputList";

import {
    Grid,
    Box,
} from "@chakra-ui/react";

import SVG from "../SVG";

import Icon1 from "../../images/illustrations/people-3.svg";
import Icon2 from "../../images/illustrations/faq-1.svg";


const YourObjectives = (props) => {
    const formName = "Your Objectives";
    const globalForm = props.getForm(formName);

    const formData = globalForm?.data || {
        internalGoals: [],
        externalGoals: []
    };

    const updateFormData = (dataKey, dataVals) => {
        formData[dataKey] = dataVals;
        props.setDataCompleted(
            formName,
            Object.values(formData).filter(_=>_.length).length === Object.keys(formData).length,
            formData
        );
        props.reload();
    }

    return (
        <FormContainer
        formName={formName}
        // subtext={"How will we create and collate our content?"}
        {...props}
        >

            <Grid templateColumns={"1fr 2fr"}>
                <Box>
                    <SVG src={Icon1} width="100%"/>
                </Box>
                <Box paddingLeft={10}>
                    <TextInputList
                        {...props}
                        heading={"Your internal goals"}
                        text={"Internal goals are about using your website to improve your business’ internal processes. They can be goals to aid teams to work more efficiently or automate mundane processes."}
                        btnText={"Add goal"}
                        data={formData.internalGoals}
                        onChange={(dataVals) => updateFormData("internalGoals", dataVals)}
                    />
                </Box>
            </Grid>

            <Grid templateColumns={"1fr 2fr"}>
                <Box>
                    <SVG src={Icon2} width="100%"/>
                </Box>
                <Box paddingLeft={10}>
                    <TextInputList
                        {...props}
                        heading={"Your external goals"}
                        text={"External goals are aimed at making an impact outside your team. These outsiders can be other employees at our company who reside outside your team or external stakeholders such as end-user consumers or partners."}
                        btnText={"Add goal"}
                        data={formData.externalGoals}
                        onChange={(dataVals) => updateFormData("externalGoals", dataVals)}
                    />
                </Box>
            </Grid>

        </FormContainer>
    );
}

export default YourObjectives;