import React, { useState } from "react";

import {
    Button,
    Center,
    Icon,
    Stack,
    Text,
    Heading,
    Divider,
    Progress,
} from "@chakra-ui/react";


import strategyStages from "../lib/StrategyStages";

import ActionFeedback from "./ActionFeedback";

const StageHeader = (props) => {
    const { stage, progress } = props;

    return (
        <>
        <Heading size={"xs"}>
            Status: &nbsp;
            <Text as="span" color={stage.scheme + ".500"}>
                {stage.heading} &nbsp;
                <Icon as={stage.icon}/>
            </Text>
        </Heading>
        <Center height="20px">
            <Divider orientation="vertical" />
        </Center>
        {/* {!!progress && <Progress colorScheme="purple" size="md" value={progress} width={"100%"}/>} */}
        </>
    );
}

const SubmitButton = (props) => {
    const [isSaving, setIsSaving] = useState(false);

    const { stage, progress, setStage, save, setStrategy, feedback, setFeedback, databaseId } = props;

    const strategyStage = strategyStages[stage];

    let hasFeedback = false;
    feedback.forEach(feedbackItem => {
        if(!feedbackItem.actioned) {
            hasFeedback = true;
        }
    })


    const changeStage = (newStage) => {
        setIsSaving(true);
        save(true, () => {
            setStage(newStage);
            setIsSaving(false);
            setStrategy(false);
        }, newStage);
    }

    if(stage === 'in_progress') {
        const isLoading = (isSaving || progress < 80);
        return (
            <Center>
                <Stack align="center">
                    <StageHeader stage={strategyStage} progress={progress}/>
                    <Button
                    rightIcon={<Icon as={strategyStages["internal_review"].icon} />}
                    size="md"
                    colorScheme="purple"
                    isDisabled={isLoading}
                    isLoading={isSaving}
                    loadingText="Saving"
                    onClick={() => changeStage('internal_review')}
                    >Submit for internal review</Button>
                </Stack>
            </Center>
        );
    } else if(stage === 'internal_review') {
        return (
            <Center>
                <Stack align="center">
                    <StageHeader stage={strategyStage}/>
                    {feedback.length && <ActionFeedback feedback={feedback} setFeedback={setFeedback} save={save} hasUnactioned={hasFeedback} databaseId={databaseId}/>}
                    {!hasFeedback && <Button
                    rightIcon={<Icon as={strategyStages["with_client"].icon} />}
                    size="md"
                    colorScheme="purple"
                    isDisabled={isSaving}
                    isLoading={isSaving}
                    loadingText="Saving"
                    onClick={() => changeStage('with_client')}
                    >Submit for client review</Button>}
                </Stack>
            </Center>
        );
    } else {
        return <></>
    }
}

export default SubmitButton;