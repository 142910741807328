import React from 'react';

import FormContainer from "../FormContainer";
import TextareaList from "../TextareaList";

const ContentStrategy = (props) => {
    const formName = "Content Strategy";

    return (
        <FormContainer
        formName={formName}
        subtext={"How will we create and collate our content?"}
        {...props}
        >

            <TextareaList cols={1} opts={[
                "Who is responsible for creating / supplying site content?",
                "How will the content be supplied?"
            ]} formName={formName} {...props}/>

        </FormContainer>
    );
}

export default ContentStrategy;