import React, { useState, useEffect } from "react";
import PagesList from "./PagesList.jsx";
import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Box,
	List,
	ListItem,
	ListIcon,
	CircularProgress,
	CircularProgressLabel,
	Button,
	Center,
	Icon
  } from "@chakra-ui/react";

import { CheckCircleIcon } from '@chakra-ui/icons';

import { MdFeedback } from "react-icons/md";

import SubmitButton from "./SubmitButton.jsx";
import StepProgress from "./StepProgress.jsx";
import PriceEstimate from "./PriceEstimate.jsx";

const Sidebar = (props) => {
	
	const {
		step,
		steps,
		setStep,
		toggleCompleted,
		pages,
		getStepProgress,
		getTotalProgress,
		stage,
		setStage,
		strategy,
		setStrategy,
		decodeGlobalId,
		save,
		feedback,
		setFeedback
	} = props;
	const databaseId = decodeGlobalId(strategy)?.id;
	const totalProgress = getTotalProgress();

	return (
		<Box h="100%" pos="relative">
		  <Accordion defaultIndex={[0]} allowMultiple={false} allowToggle={true} index={step}>

			{
				Object.keys(steps).map((stepName, stepIndex) => {
					const subSteps = steps[stepName];
					const progress = getStepProgress(subSteps);
					return (
						<AccordionItem borderTopWidth={stepIndex === 0 ? 0 : 1} key={"step-item-" + stepIndex} isDisabled={subSteps?.data?.locked}>
							<h2>
								<AccordionButton
									onClick={() => setStep(stepIndex)}
									// _expanded={{ bg: "purple.500", color: "white" }}
								>
									<Box flex="1" textAlign="left">
									<StepProgress progress={progress}/>
									{stepName}
									</Box>
									<AccordionIcon />
								</AccordionButton>
							</h2>
							<AccordionPanel pb={stepIndex !== 8 && 4}>
							{stepIndex === 8 ? <PagesList {...props}/> : (
							<List pl={0}>
								{Object.keys(subSteps).map((subStepIndex) => {
									const subStep = subSteps[subStepIndex];
									if(subStepIndex === "data") return;
									return (
										<ListItem fontSize={14} key={"substep-item-" + stepIndex + "-" + subStepIndex}>
											<ListIcon
												as={CheckCircleIcon}
												color={subStep.completed ? "green.500" : "gray.300"}
												onClick={() => toggleCompleted(stepName, subStepIndex)}
											/>
											{subStepIndex}
										</ListItem>
									)}
								)}
							</List>
							)}
							</AccordionPanel>
						</AccordionItem>
					)
				}
			)}
		  </Accordion>
			<br />
			<SubmitButton
				stage={stage}
				setStage={setStage}
				setStrategy={setStrategy}
				progress={totalProgress}
				save={save}
				feedback={feedback}
				setFeedback={setFeedback}
				databaseId={databaseId}
			/>

		  <PriceEstimate pages={pages} steps={steps} calculateEstimate={props.calculateEstimate} />
		</Box>
	  );
}

export default Sidebar;