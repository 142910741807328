import React, { useState, useEffect } from "react";

import StepContainer from "../components/StepContainer.jsx";

import Icon1 from "../images/illustrations/post-it-2.svg";

import {
    Box,
    Heading,
    Text,
    Input,
    Flex,
    Center,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
    FormControl,
    FormLabel,
    Button,
    Icon,
    IconButton,
    Image,
    Textarea,
    RadioGroup,
    Stack,
    Radio,
    InputGroup,
    InputRightElement
} from "@chakra-ui/react";

import { BsPlusCircleFill } from "react-icons/bs";
import { MinusIcon } from "@chakra-ui/icons";

import SlickplanIntegration from "../integrations/Slickplan";
import TeamworkIntegration from "../integrations/Teamwork";

const defaultHours = [
    {
        "for": "Initial site setup and installation",
        "description": "Includes project setup, setup of development site and instalation of site framework",
        "hours": false
    },
    {
        "for": "Testing and revisions",
        "description": "Allocated time for thourough site testing and revision changes.",
        "hours": false
    },
    {
        "for": "Site migration and launch",
        "description": "Allocated time for migrating the website to the live web server and launch.",
        "hours": false
    },
    {
        "for": "Website training session",
        "description": false,
        "hours": false
    }
];

const Extras = (props) => {

    const [extraData, setExtraData] = useState(props.steps.Extras?.data?.data || {
        hours: defaultHours,
        discountType: "none",
        discountVal: 0
    });

    const handleAddHours = () => {
        extraData.hours.push({
            "for": "Additional time reason",
            "description": "Additional time description",
            "hours": false
        });
        props.steps.Extras.data.data = extraData;
        props.reload();
    }

    const handleRemoveHour = (hoursIndex) => {
        extraData.hours = extraData.hours.filter((h, i) => {
            return i !== hoursIndex;
        });

        props.steps.Extras.data.data = extraData;
        props.reload();
    }

    const handleHoursChange = (hoursIndex, hoursKey, hoursValue) => {
        if(hoursKey === "hours") {
            hoursValue = parseFloat(hoursValue);
        }
        extraData.hours[hoursIndex][hoursKey] = hoursValue;
        props.reload();
    }
    
    const handleDiscountTypeChange = (value) => {
        extraData.discountType = value;
        props.steps.Extras.data.data = extraData;
        props.reload();
    }

    const handleDiscountValueChange = (value) => {
        extraData.discountVal = parseFloat(value);
        props.steps.Extras.data.data = extraData;
        props.reload();
    }

    const allHours = extraData?.hours || [];

    return (
        <StepContainer {...props}
            formInfo={{
                image: Icon1,
                heading: "Extras",
                text: "This is where any additional things that aren't specified elsewhere will be defined"
            }}
        >
            <Box w="100%" maxWidth="4xl" margin="auto" p={4} textAlign="right">
                <Heading size="lg" fontWeight="normal" textAlign="left" mb={4}>Extra Hours Configuration</Heading>
                {allHours.map((hoursItem, hoursIndex) => (
                <FormControl id={"hours-item-" + hoursIndex} key={hoursIndex + "-" + hoursItem.for}>
                    <Flex borderBottomWidth={1}>
                        <Center w="30px">
                            {hoursIndex > defaultHours.length - 1 && (
                                <IconButton
                                    icon={<MinusIcon />}
                                    colorScheme="red"
                                    size="sm"
                                    onClick={() => handleRemoveHour(hoursIndex)}
                                ></IconButton>
                            )}
                        </Center>
                        <Box flex="1" p={10} pt={3} pb={3} textAlign="left">
                            {hoursIndex <= defaultHours.length - 1 && <>
                                <FormLabel>
                                    <Heading size="sm">{hoursItem.for}</Heading>
                                    <Text fontSize="sm">{hoursItem.description}</Text>
                                </FormLabel>
                            </>}
                            {hoursIndex > defaultHours.length - 1 && <>
                                <Input
                                    variant="flushed"
                                    defaultValue={hoursItem.for}
                                    size="md"
                                    fontWeight="bold"
                                    mb={1}
                                    onBlur={(e) => handleHoursChange(hoursIndex, "for", e.target.value)}
                                ></Input>
                                <Textarea
                                    variant="flushed"
                                    defaultValue={hoursItem.description}
                                    size="sm"
                                    minHeight={16}
                                    onBlur={(e) => handleHoursChange(hoursIndex, "description", e.target.value)}
                                ></Textarea>
                            </>}
                        </Box>
                        <Center w="250px" p={2}>
                            <NumberInput size="sm" defaultValue={hoursItem.hours ? hoursItem.hours : ""} step={0.25} precision={2} min={0}>
                                <NumberInputField placeholder="Number of hours" onBlur={(e) => handleHoursChange(hoursIndex, "hours", e.target.value)}/>
                                <NumberInputStepper>
                                    <NumberIncrementStepper />
                                    <NumberDecrementStepper />
                                </NumberInputStepper>
                            </NumberInput>
                        </Center>
                    </Flex>
                </FormControl>
                ))}
                <br />
                <Button
                    width="100%"
                    maxWidth="sm"
                    size={"md"}
                    colorScheme={"purple"}
                    rightIcon={<Icon as={BsPlusCircleFill} verticalAlign="middle"/>}
                    onClick={handleAddHours}
                >Add Additional Time</Button>
            </Box>

            <Box w="100%" maxWidth="4xl" margin="auto" p={4}>
                <Heading size="lg" fontWeight="normal" textAlign="left" mb={4}>Discounted Price</Heading>

                <Box p={4}>
                    <RadioGroup defaultValue={extraData.discountType || "none"} onChange={handleDiscountTypeChange}>
                        <Text fontWeight="bold">Discount Type</Text>
                        <Stack spacing={5} direction="row">
                            <Radio colorScheme="purple" value="none" cursor="pointer">None</Radio>
                            <Radio colorScheme="purple" value="percent" cursor="pointer">Percentage</Radio>
                            <Radio colorScheme="purple" value="fixed" cursor="pointer">Fixed Amount</Radio>
                        </Stack>
                    </RadioGroup>
                </Box>
                
                {extraData.discountType && extraData.discountType !== "none" && <Box p={4}>
                    <FormControl>
                        <FormLabel>
                            <Text fontWeight="bold">Discount Value</Text>
                        </FormLabel>
                        <NumberInput
                            size="sm"
                            defaultValue={extraData.discountVal ? (extraData.discountType === "percent" && extraData.discountVal > 100 ? 100 : extraData.discountVal) : ""}
                            step={1}
                            min={0}
                            max={extraData.discountType === "percent" ? 100 : undefined}
                            maxW="200px"
                            >
                            <InputGroup>
                                <NumberInputField
                                    placeholder={extraData.discountType === "percent" ? "Percentage" : "Fixed Amount"}
                                    onBlur={(e) => handleDiscountValueChange(e.target.value)}
                                    data-lpignore="true"
                                    data-form-type="text"
                                    />
                                <InputRightElement
                                pointerEvents="none"
                                color="gray.300"
                                fontSize="1.2em"
                                children={extraData.discountType === "percent" ? "%" : "$"}
                                />
                            </InputGroup>
                        </NumberInput>
                    </FormControl>
                </Box>}

            </Box>

            <Box w="100%" maxWidth="4xl" margin="auto" p={4}>
                <Heading size="lg" fontWeight="normal" textAlign="left" mb={4}>Integrations</Heading>

                <SlickplanIntegration {...props}/>
                <br /><br />
                <TeamworkIntegration {...props}/>

            </Box>

        </StepContainer>
    );
}

export default Extras;