import React from 'react';

import FormContainer from "../FormContainer";
import TextareaList from "../TextareaList";

import {
    FormLabel,
    Textarea
} from "@chakra-ui/react";


const SEO = (props) => {
    const formName = "Video & Photography";
    const globalForm = props.getForm(formName);

    return (
        <FormContainer
        formName={formName}
            //subtext={"What role does SEO/SEO Strategy play in the website project?"}
        {...props}
        >
            <TextareaList cols={1} opts={[
                "How do we want to use your video and photography in the new site?",
                "Is additional photography or videography required?",
                "Who is reponsible for providing images and video?",
                "If we are supplying stock imagery/videography, who is paying?",
            ]} formName={formName} {...props}/>

        </FormContainer>
    );
}

export default SEO;