import React, { useState, useEffect } from "react";
import { useMutation } from '@apollo/client';
import { LOGIN_USER, REGISTER_USER } from '../apollo/queries.js';

import {
    Flex,
    Box,
    FormControl,
    FormLabel,
    Input,
    Stack,
    Button,
    Text,
    Image,
    Alert,
    Divider,
    SlideFade
} from '@chakra-ui/react';

const logo = "https://www.embarkagency.com.au/wp-content/uploads/2021/06/Embark-logo-blue.svg";

  
export default function LoginForm(props) {
    const { login, duration } = props;

    const [isShowing, setShow] = useState(false);

    useEffect(() => {
        setShow(true);
        return () => setShow(false)
    }, []);
    

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const [shouldRegister, setShouldRegister] = useState(false);
    
    const [loginUser, loginData] = useMutation(LOGIN_USER, {
        onError: () => null,
        onCompleted: (data) => {
            if(data && data.login && data.login.authToken) {
                login(data.login.authToken);
            }
        }
    });

    const [registerUser, registerData] = useMutation(REGISTER_USER, {
        onError: () => null,
        onCompleted: (data) => {
            setShouldRegister(false);
            loginUser({variables: { username, password }});
        }
    });

    const handleAction = (e) => {
        e.preventDefault();

        if(shouldRegister) {
            registerUser({variables: { firstName, lastName, email, username, password }});
        } else if(!shouldRegister) {
            loginUser({variables: { username, password }});
        }
    }
    
    return (
        <Flex
        minH={'100vh'}
        align={'center'}
        justify={'center'}
        bg={"white"}>

            <SlideFade in={isShowing} transition={{ enter: {duration}, exit: {duration} }}>
                <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6} width={500} overflowY="auto" maxH="100vh">
                    <Stack align={'center'}>
                        <Image alt="Embark Logo" h={8} src={logo} ignoreFallback={true}></Image>
                    </Stack>
                    <Box
                    rounded={'lg'}
                    boxShadow={'lg'}
                    bg={'white'}
                    p={8}>
                    <Stack spacing={4}>
                        
                        <Text fontSize="2xl" textAlign="center">{shouldRegister ? "Register Account" : "Sign In"}</Text>
                        <Text fontSize={'lg'} color={'gray.600'} textAlign="center">
                            {
                                shouldRegister ?
                                    <span>Register an account for a new client <br />to begin with their website strategy ✌️</span> :
                                    <span>Sign in to an existing clients account <br />to manage their website strategy ✌️</span>
                            }
                            <br />
                            or &nbsp;
                        <Button variant="link" colorScheme={"purple"}
                            sx={{ outline: "none !important" }}
                            onClick={() => setShouldRegister(!shouldRegister)}
                            >
                                {
                                    shouldRegister ?
                                        <span>Sign In</span> : 
                                        <span>Register a new account</span>
                                }
                                
                            </Button>
                        </Text>
                        <Divider />
                        {
                            (shouldRegister ? registerData.error : loginData.error) &&
                            (<Alert status="error">{(shouldRegister ? registerData.error.message : loginData.error.message)}</Alert>)
                        }

                        <FormControl id="username">
                            <FormLabel>{shouldRegister ? "Username" : "Username / Email Address"}</FormLabel>
                            <Input
                                type="text"
                                defaultValue={username}
                                onChange={(e) => setUsername(e.target.value)}
                                onKeyUp={(e) => e.keyCode === 13 && handleAction(e) }
                            />
                        </FormControl>
                        {shouldRegister ? (
                        <>
                        <FormControl id="email">
                            <FormLabel>Email</FormLabel>
                            <Input
                                type="email"
                                defaultValue={email}
                                onChange={(e) => setEmail(e.target.value)}
                                onKeyUp={(e) => e.keyCode === 13 && handleAction(e) }
                            />
                        </FormControl>
                        <FormControl id="firstName">
                            <FormLabel>First Name</FormLabel>
                            <Input
                                type="text"
                                defaultValue={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                onKeyUp={(e) => e.keyCode === 13 && handleAction(e) }
                            />
                        </FormControl>
                        <FormControl id="lastName">
                            <FormLabel>Last Name</FormLabel>
                            <Input
                                type="text"
                                defaultValue={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                onKeyUp={(e) => e.keyCode === 13 && handleAction(e) }
                            />
                        </FormControl>
                        </>
                        ) : undefined}
                        <FormControl id="password">
                            <FormLabel>Password</FormLabel>
                            <Input
                                type="password"
                                defaultValue={password}
                                onChange={(e) => setPassword(e.target.value)}
                                onKeyUp={(e) => e.keyCode === 13 && handleAction(e) }
                            />
                        </FormControl>
                        <Stack spacing={10}>
                            <Stack
                                direction={{ base: 'column', sm: 'row' }}
                                align={'start'}
                                justify={'space-between'}>
                                {/* <Checkbox>Remember me</Checkbox> */}
                                {/* <Link color={'purple.400'}>Forgot password?</Link> */}
                            </Stack>
                            <Button
                                isLoading={shouldRegister ? registerData.loading : loginData.loading}
                                loadingText="Loading"
                                onClick={handleAction}
                                bg={'purple.500'}
                                color={'white'}
                                _hover={{
                                bg: 'purple.400',
                                }}>
                                {shouldRegister ? "Register Account" : "Sign In"}
                            </Button>
                        </Stack>

                    </Stack>
                    </Box>
                </Stack>
            </SlideFade>
        </Flex>
    );
}