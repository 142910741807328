import React from 'react';

import FormContainer from "../FormContainer";
import TextareaList from "../TextareaList";

import {
    FormLabel,
    Textarea
} from "@chakra-ui/react";


const UnansweredQuestions = (props) => {
    const formName = "Unanswered Questions";
    const globalForm = props.getForm(formName);

    return (
        <FormContainer
        formName={formName}
            //subtext={"Are there any critical behaviours (personnel who may cause blocks in the project timeline) that we need to be aware of?"}
        {...props}
        >
            <TextareaList cols={1} opts={[
                "Are there any unanswered questions that need to be raised?",
            ]} formName={formName} {...props}/>

        </FormContainer>
    );
}

export default UnansweredQuestions;