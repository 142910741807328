import React from 'react';
import {
	Box,
	Button,
	Center,
	Image
} from "@chakra-ui/react";

import { ArrowLeftIcon, ArrowRightIcon } from "@chakra-ui/icons";

const fullSectionPreview = false;

const InsertSection = (props) => {
	const { index, sectionData, selectedSection, handleNewSection } = props;
	if(selectedSection) {
	
		if(fullSectionPreview) {
			return (
				<Box position="relative">
					<Center
						bg={"grey.500"}
						color={"black"}
						textAlign={"center"}
						borderWidth={2}
						borderColor={"purple.500"}
						borderStyle={"dashed"}
						position={"absolute"}
						width={"100%"}
						height={"100%"}
						zIndex={4}
					>
	
						<Button onClick={() => handleNewSection(index)}>Insert Section Here</Button>
					</Center>
					<Image
						src={sectionData.image.sourceUrl || sectionData.image}
						alt={`Section placeholder ${sectionData.title} Image`}
						ignoreFallback={true}
						opacity={0.2}
					></Image>
				</Box>
			);
		} else {
			return (
			<Box
				bg={"grey.500"}
				position={"relative"}
				padding={0}
				zIndex={5}
				left={0}
			>

				<Center
				position={"absolute"}
				left={0}
				width={"100%"}
				borderColor={"purple.500"}
				borderTopWidth={1}
				borderStyle={"dashed"}
				>
					<Button
						className="insert-section"
						colorScheme={"purple"}
						onClick={() => handleNewSection(index)}
						position="relative"
						top={"-20px"}
						leftIcon={<ArrowLeftIcon />}
						rightIcon={<ArrowRightIcon />}
					>Click to insert</Button>
				</Center>
	
	
			</Box>
			);
		}
	} else {
		return (<></>)
	}
}


export default InsertSection;