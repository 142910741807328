import React from 'react'

import { useQuery } from '@apollo/client';
import { SECTION_CONTENT_TYPES } from '../apollo/queries.js';

import {
	SimpleGrid,
	Progress,
	Fade,
	Box,
	Heading,
	Accordion,
	AccordionItem,
	AccordionButton,
	AccordionPanel,
	AccordionIcon,
    Text,
    Alert,
    AlertIcon
} from "@chakra-ui/react";

// import { AlertIcon } from "@chakra-ui/icons";

import HighlightResult from "./HighlightResult";

import SectionCard from "./SectionCard.jsx";

const SectionSelect_Types = (props) => {

	const {
		searchInput,
		sections,
		duration,
        filterQuery,
        typeName,
	} = props;

	const sectionFilter = useQuery(filterQuery);

	const getFilteredSections = (filteredData) => {
		let newFilteredData = [];
		if(filteredData) {
			filteredData[typeName].nodes.forEach(allTypeData => {
				const typeData = {
					...allTypeData
				};
	
				typeData.sections = typeData.sections.nodes.map(section => {
					return sections[section.slug];
				});
	
				newFilteredData.push(typeData);
			})
		}

		newFilteredData = newFilteredData.sort((a, b) => {
			if (a.name < b.name) return -1;
			if (a.name > b.name) return 1;
			return 0;
		})
		.filter(r => searchInput ? r.name.toLowerCase().includes(searchInput.toLowerCase()) : true);
		
		return newFilteredData;
	}

	const filteredSections = getFilteredSections(sectionFilter.data);

    return (
        <>
        <Fade in={sectionFilter.loading} transition={{ enter: { duration: 0 }, exit: { duration: 0 } }}>
            {sectionFilter.loading && <Progress size="xs" isIndeterminate colorScheme={"purple"}/>}
        </Fade>
        <Fade in={sectionFilter.error} transition={{ enter: {duration}, exit: {duration} }}>
            {sectionFilter.error && <p>Error: ${sectionFilter.error.message}</p>}
        </Fade>
        <Fade in={!sectionFilter.loading && filteredSections} transition={{ enter: {duration}, exit: {duration} }}>

			<Accordion>

			{filteredSections && filteredSections.length > 0 && filteredSections.map((sectionType, i) => {
                return (
                    <AccordionItem key={"section-type-filter-" + sectionType.name}>
                        <AccordionButton>
                            <Box flex="1" textAlign="left" fontSize={"sm"} color={"gray.700"}>
                                <HighlightResult
                                    keyword={searchInput}
                                    _highlight={{
                                        "bg": "blue.100",
                                    }}
                                >{sectionType.name}
                                </HighlightResult>
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4}>
                            <SimpleGrid columns={1} spacing={2}>
                                {sectionType.sections.length === 0 && (
                                <Alert status="error" variant="subtle">
                                    <Text fontSize={"sm"}>
                                        No sections
                                    </Text>
                                </Alert>
                                )}
                                {sectionType.sections.map((section, j) => (
                                    <SectionCard
                                        key={"section-select-" + section.slug}
                                        section={section}
                                        selectedSection={props.selectedSection}
                                        setSelectedSection={props.setSelectedSection}
                                    />
                                ))}
                            </SimpleGrid>
                        </AccordionPanel>
                    </AccordionItem>
                );
			})}
			</Accordion>
            {filteredSections && filteredSections.length === 0 && (
                <Alert status="error" variant="solid">
                    <AlertIcon />
                    No results
                </Alert>
            )}
        </Fade>
        </>
    );
}

export default SectionSelect_Types;