import React, { useState } from 'react';

import uniqid from "uniqid";

import {
    Grid,
    Heading,
    Text,
    Box,
    List,
    ListItem,
    Input,
    Flex,
    Button,
    IconButton,
    Textarea
} from "@chakra-ui/react";

import {
    DeleteIcon,
    AddIcon,
    EditIcon,
    CopyIcon,
    MinusIcon
} from '@chakra-ui/icons';

import MomentLocaleUtils, {
    formatDate,
    parseDate,
} from 'react-day-picker/moment';


import DayPickerInput from "react-day-picker/DayPickerInput";
import "../styles/react-day-picker.css";


const TextInputList = (props) => {

    const {
        heading,
        text,
        btnText,
        placeholder,
        onChange,
        onRemove,
        min,
        max,
        type
    } = props;


    let defaultVal = "";
    if(type === "datedesc") {
        defaultVal = {
            date: "",
            desc: "",
        };
    }

    let [listItems, setListItems] = useState(props.data || []);

    const [focus, setFocus] = useState(false);

    const removeItem = (listItemId) => {
        listItems = listItems.filter(listItem => listItem.id !== listItemId);
        setListItems(listItems);
        if(onChange) {
            onChange(listItems);
        }
        if(onRemove) {
            onRemove(listItemId);
        }
    }

    const setItem = (listIndex, val) => {

        if(typeof val === "object") {
            if(!listItems[listIndex].value) {
                listItems[listIndex].value = defaultVal;
            }
            for(const property in val) {
                listItems[listIndex].value[property] = val[property];
            }
        } else {
            listItems[listIndex].value = val;
        }
        setListItems(listItems)
        if(onChange) {
            onChange(listItems);
        }
    }

    const addItem = () => {
        if(!max || (max && listItems.length < max)) {
            const id = uniqid();
            listItems.push({
                id,
                value: defaultVal
            });
            setListItems(listItems);
            setFocus(id);
            if(onChange) {
                onChange(listItems);
            }
        }
    }

    const realPlaceholder = placeholder ?? "Type item here...";

    const atMin = !min || (min && listItems.length > min);

    const notDefault = ["date", "datedesc", "textarea"];

    return (
        <Box>
            {(heading || text) && (<>
                <br />
                {heading && <Heading size={"md"}>{heading}</Heading>}
                {text && <Text>{text}</Text>}
                <br />
            </>)}
            <List>
                {listItems.map((listItem, listIndex) => {

                    const isFirst = listIndex === 0;
                    const isLast = listIndex === listItems.length - 1;
                    const listItemVal = listItem.value || listItem.text;

                    return (
                        <ListItem key={listItem.id}>
                            <Flex data-type={type}>
                                {atMin && <IconButton
                                    borderEndRadius={0}
                                    borderBottomLeftRadius={!isLast && 0}
                                    borderTopLeftRadius={!isFirst && 0}
                                    aria-label={btnText ?? "Add Item"}
                                    colorScheme="red"
                                    icon={<MinusIcon />}
                                    size={"sm"}
                                    onClick={() => removeItem(listItem.id)}
                                />}

                                {/* Date */}
                                {type === "date" && <DayPickerInput
                                    onDayChange={(val) => setItem(listIndex, formatDate(val))}
                                    formatDate={formatDate}
                                    parseDate={parseDate}
                                    value={listItemVal}
                                    showOverlay={listItem.id===focus}
                                    placeholder={`${formatDate(new Date())}`}
                                />}

                                {/* Date & Description */}
                                {type === "datedesc" && <>                                
                                <DayPickerInput
                                    onDayChange={(val) => setItem(listIndex, {
                                        date: formatDate(val)
                                    })}
                                    formatDate={formatDate}
                                    parseDate={parseDate}
                                    value={listItemVal.date}
                                    placeholder={`${formatDate(new Date())}`}
                                />
                                <Textarea
                                    placeholder={realPlaceholder}
                                    size={"sm"}
                                    borderBottomRightRadius={0}
                                    borderStartRadius={!atMin && 0}
                                    borderTopRightRadius={!isFirst && 0}
                                    variant={"outline"}
                                    defaultValue={listItemVal.desc}
                                    focusBorderColor={"purple.500"}
                                    onBlur={(e) => setItem(listIndex, {
                                        desc: e.target.value
                                    })}
                                    id={"list-item-input-" + listItem.id}
                                />
                                </>
                                }

                                {/* Textarea */}
                                {type === "textarea" && <Textarea
                                    placeholder={realPlaceholder}
                                    size={"sm"}
                                    borderBottomRightRadius={0}
                                    borderStartRadius={!atMin && 0}
                                    borderTopRightRadius={!isFirst && 0}
                                    variant={"outline"}
                                    defaultValue={listItemVal}
                                    autoFocus={listItem.id===focus}
                                    focusBorderColor={"purple.500"}
                                    onBlur={(e) => setItem(listIndex, e.target.value)}
                                    id={"list-item-input-" + listItem.id}
                                />}

                                {/* Text input / default */}
                                {!notDefault.includes(type) && <Input
                                    placeholder={realPlaceholder}
                                    size={"sm"}
                                    borderBottomRightRadius={0}
                                    borderStartRadius={!atMin && 0}
                                    borderTopRightRadius={!isFirst && 0}
                                    variant={"outline"}
                                    defaultValue={listItemVal}
                                    autoFocus={listItem.id===focus}
                                    focusBorderColor={"purple.500"}
                                    onBlur={(e) => setItem(listIndex, e.target.value)}
                                    id={"list-item-input-" + listItem.id}
                                    onKeyDown={(e) => e.key === "Enter" && addItem()}
                                />}
                            </Flex>
                        </ListItem>
                    );
                })}
            </List>


            {(!max || (max && listItems.length < max)) && <Box textAlign={listItems.length > 0 ? "right" : "left"}>
                <Button
                    onClick={() => addItem()}
                    rightIcon={<AddIcon />}
                    colorScheme="purple"
                    size={"sm"}
                    borderTopRadius={listItems.length > 0 && 0}
                >
                    {btnText ?? "Add Item"}
                </Button>
            </Box>}
        </Box>
    );
}

export default TextInputList;