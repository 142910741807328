import React from 'react';
import { Button, Image } from "@chakra-ui/react";

const Teamwork = (props) => {
    return (
        <React.Fragment>
            <Button
                bg="#ff22b1"
                color="#FFF"
                _hover={{bg: "#b2197b"}}
                _active={{bg: "#b2197b"}}

                padding="25px 30px"
            >Login with Teamwork</Button>
        </React.Fragment>
    );
}

export default Teamwork;