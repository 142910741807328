import React from "react";
import {
  Box,
  Image,
  Flex,
  Button,
  Spacer,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  HStack,
  Text,
  Center,
  Editable,
  EditablePreview,
  EditableInput,
  Avatar,
  Spinner,
  Icon
} from "@chakra-ui/react";

import { IoLogOutOutline } from "react-icons/io5";
import { RiSave3Fill } from "react-icons/ri";

import { ChevronDownIcon, ArrowBackIcon } from '@chakra-ui/icons'

import CreateStrategy from './CreateStrategy.jsx';
import strategyStages from "../lib/StrategyStages";

const logo = "https://www.embarkagency.com.au/wp-content/uploads/2021/06/Embark-logo-blue.svg";


const AvatarMenu = (props) => {
	const { save, logout, strategy, clientData } =  props;

	return (
		<Menu autoSelect={false}>
			<MenuButton as={Button} rightIcon={<ChevronDownIcon />} fontWeight="regular">
				{/* <Avatar bg="purple.500" size="sm"/> */}
				<Avatar
					size="sm"
					name={clientData?.firstName + " " + clientData?.lastName}
					src={clientData?.avatar.url}
				/>
			</MenuButton>
			<MenuList>
				{strategy && (
				<MenuItem
					icon={<RiSave3Fill />}
					command="⌘S" onClick={() => save()}>Save</MenuItem>
				)}
				<MenuItem
					icon={<IoLogOutOutline />}
					onClick={() => logout()}>Logout</MenuItem>
			</MenuList>
		</Menu>
	);
}



const Header = (props) => {

	const {
		strategy,
		setStrategy,
		name,
		setName,
		save,
		logout,
		clientData,
		isAdmin,
		isSaving,
		createStrategy,
		createLoading
	} = props;

	return (
		<Flex h="60px" boxShadow="md" position="relative" zIndex="1" pl={10} pr={10}>
			<HStack spacing={10} align={"center"}>
				<Image alt="Embark Logo" h={8} src={logo} ignoreFallback={true}></Image>
				{strategy && isAdmin() && (
					<Button
						onClick={() => setStrategy(false)}
						variant="link"
						colorScheme={"purple"}
						position={"absolute"}
						left={"180px"}
						leftIcon={<ArrowBackIcon />}
					>Back to strategies</Button>
				)}
			</HStack>
			<Spacer />
			{strategy && (
			<Center p={2.5}>
				<Editable
					defaultValue={name}
					size="md"
					textAlign="center"
					onChange={val => setName(val)}
					onSubmit={() => save()}
					>
					<EditablePreview />
					<EditableInput />
				</Editable>&nbsp;-&nbsp;Website Strategy
			</Center>
			)}
			<Spacer />
			<Center>
				{strategy && (
					<HStack spacing={3}>
						{isSaving && (
							<Spinner
							size="md"
							color={"purple.500"}
							emptyColor="gray.200"
							position={"absolute"}
							right={"140px"}
							/>
						)}
						<AvatarMenu save={save} logout={logout} strategy={strategy} clientData={clientData}/>
					</HStack>
				)}
				{!strategy && (
					<HStack spacing={6}>
						<CreateStrategy createStrategy={createStrategy} createLoading={createLoading}/>
						<HStack spacing={3}>
							<Text>Welcome back {clientData?.firstName}</Text>
							{<AvatarMenu save={save} logout={logout} strategy={strategy} clientData={clientData}/>}
						</HStack>
					</HStack>
				)}
			</Center>
		</Flex>
	);
}

export default Header;