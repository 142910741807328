import React, { useState, useEffect } from "react";

import StepContainer from "../components/StepContainer.jsx";

import Icon from "../images/illustrations/done-1.svg";

const BusinessObjectives = (props) => {
    return (
        <StepContainer {...props}
            formInfo={{
                image: Icon,
                heading: "Your business objectives",
                text: "Understanding your business objectives is the first step to success. In this step, we’ll cover your goals, measurable success criteria and your call to actions."
            }}
        >

        </StepContainer>
    );
}

export default BusinessObjectives;