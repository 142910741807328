import React, { useState } from "react";

import uniqid from "uniqid";

import SortableTree from 'react-sortable-tree';

// import FileExplorerTheme from 'react-sortable-tree-theme-file-explorer';
import '../styles/react-sortable-tree.css';

import { GrDrag } from "react-icons/gr";
import { BiPurchaseTag } from "react-icons/bi"

import { DeleteIcon, AddIcon, EditIcon, CopyIcon } from '@chakra-ui/icons'
import {
	Box,
	IconButton,
	Tooltip,
	Text,
	Flex,
	Center,
	Spacer,
	Divider,
	Icon,
	HStack,
} from "@chakra-ui/react";

import NewPage from "./NewPage";
import PageTreeViewPopup from "./PageTreeViewPopup";
import TagPopover from "./TagPopover";

const PagesList = (props) => {
	const { pages, setPages, reload, changePage, name, page } = props;
	const [addParentId, setAddParentId] = useState(false);

	const renamePage = (title, pageId) => {
        const setPageTitle = (ps) => {
            for(let i = 0; i < ps.length; i++) {
                if(ps[i].id === pageId) {
                    ps[i].title = title;
                } else if(ps[i].children) {
                    setPageTitle(ps[i].children);
                }
            }
        }

        setPageTitle(pages);
		setPages(pages);
		reload();
	}

	const removePage = (pageId) => {
        const findAndRemove = (ps) => {
            for(let i = 0; i < ps.length; i++) {
                if(ps[i].id === pageId) {
					ps.splice(i, 1);
                } else if(ps[i].children) {
                    findAndRemove(ps[i].children);
                }
            }
        }

		findAndRemove(pages);
		setPages(pages);
		reload();
	}

	const duplicatePage = (page) => {
		const copy = JSON.parse(JSON.stringify(page));
		copy.id = uniqid();
		copy.title += " - Copy";

        const addToParent = (ps) => {
			let addToThis = -1;
            for(let i = 0; i < ps.length; i++) {
                if(ps[i].id === page.id) {
					addToThis = i;
                } else if(addToThis === -1 && ps[i].children) {
                    addToParent(ps[i].children);
                }
            }

			if(addToThis > -1) {
				ps.splice(addToThis+1, 0, copy);
			}
        }

		addToParent(pages);
		setPages(pages);
		reload();
	}

	const addTag = (pageId, label, color) => {
        const addPageTag = (ps) => {
            for(let i = 0; i < ps.length; i++) {
                if(ps[i].id === pageId) {
					if(!ps[i].tags) {
						ps[i].tags = [];
					}

                    ps[i].tags.push({id: uniqid(), label, color});
                } else if(ps[i].children) {
                    addPageTag(ps[i].children);
                }
            }
        }

        addPageTag(pages);
		setPages(pages);
		reload();
	}

	const removeTag = (pageId, tagId) => {
        const removePageTag = (ps) => {
            for(let i = 0; i < ps.length; i++) {
                if(ps[i].id === pageId) {
					ps[i].tags = ps[i].tags.filter(t => t.id !== tagId);
                } else if(ps[i].children) {
                    removePageTag(ps[i].children);
                }
            }
        }

        removePageTag(pages);
		setPages(pages);
		reload();
	}

	return (
		<Box height={"fit-content"}>

			{addParentId && (
			<NewPage
				addParentId={addParentId}
				setAddParentId={setAddParentId}
				{...props}
			/>
			)}

			<Flex>
				<Center>
					<Tooltip hasArrow placement="auto" label="Add page" fontSize="sm" bg="purple.500">
						<IconButton
						onClick={() => { setAddParentId(-1) }}
						aria-label="Add Child Page"
						size="sm"
						icon={<AddIcon />}
						id={"add-new-page-button"}
						colorScheme="purple"
						/>
					</Tooltip>
				</Center>
				<Spacer />
				<Center>
					<PageTreeViewPopup pages={pages} changePage={changePage} strategyName={name}/>
				</Center>
			</Flex>
			<Divider mt={5} mb={5}/>

			<SortableTree
			className="pages-list"
			treeData={pages}
			getNodeKey={({ node }) => node.id}
			rowHeight={24}
			scaffoldBlockPxWidth={20}
			onChange={treeData => setPages(treeData)}
			generateNodeProps={extendedNode => ({
				buttons: [

					<Tooltip label="Add child" fontSize="sm">
						<IconButton
						bg={"transparent"}
						onClick={() => { setAddParentId(extendedNode.node.id) }}
						aria-label="Add Child Page"
						size="xs"
						icon={<AddIcon />}
						m="2px"
						/>
					</Tooltip>,

					<Tooltip label="Duplicate" fontSize="sm">
						<IconButton
						bg={"transparent"}
						onClick={() => { duplicatePage(extendedNode.node) }}
						aria-label="Duplicate Page"
						size="xs"
						icon={<CopyIcon />}
						m="2px"
						/>
					</Tooltip>,

					<TagPopover addTag={addTag} pageId={extendedNode.node.id}/>,

					<Tooltip label="Delete" fontSize="sm">
						<IconButton
						bg={"transparent"}
						onClick={() => { removePage(extendedNode.node.id) }}
						colorScheme="red"
						color={"red.500"}
						_hover={{color: "white", bg: "red.500"}}
						aria-label="Delete Page"
						size="xs"
						icon={<DeleteIcon />}
						m="2px"
						/>
					</Tooltip>,

				],
				title: (
					// <Editable
					// onChange={name => renamePage(name, extendedNode.node.id)}
					// defaultValue={extendedNode.node.title}
					// fontSize={14}
					// fontWeight={"normal"}
					// >
					// 	<EditablePreview />
					// 	<EditableInput />
					// </Editable>
					<>
						<Text
							// fontWeight={"normal"}
							as={"div"}
							fontSize={14}
							_hover={{color: extendedNode.node.id === page ? "purple.500" : "gray.500"}}
							color={extendedNode.node.id === page ? "purple.500" : "black"}
							fontWeight={extendedNode.node.id === page ? "bold" : "normal"}
							cursor={"pointer"}
							onClick={() => { changePage(extendedNode.node.id) }}
							// maxWidth={160}
							display="inline"
							isTruncated
						>
							<Icon as={GrDrag} color="gray.700"/>
							&nbsp;&nbsp;
							{extendedNode.node.tags && extendedNode.node.tags.length > 0 && (
								<>
									<HStack display="inline" spacing={1}>
										{extendedNode.node.tags.map((tag, tagIndex) => (
										<Tooltip key={"page-tag-" + tagIndex} hasArrow placement="top" label={tag.label} fontSize="sm" bg={tag.color + ".500"}>
											<Box
												w={3} h={3}
												borderRadius="full"
												bg={tag.color + ".500"}
												display="inline-block"
												verticalAlign="middle"
												onClick={() => removeTag(extendedNode.node.id, tag.id)}
											></Box>
										</Tooltip>
										))}
									</HStack>
									&nbsp;&nbsp;
								</>
							)}
							{extendedNode.node.title}
						</Text>
					</>
				)
			})}
			/>
			<br />
		</Box>
	);
}

export default PagesList;