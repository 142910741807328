import React, { useState, useEffect } from "react";

import StepContainer from "../components/StepContainer.jsx";

import SEOForm from "../components/forms/SEO.jsx";

import Icon from "../images/illustrations/marketing-target-2.svg";

const SEO = (props) => {

    return (
        <StepContainer {...props}
            formInfo={{
                image: Icon,
                heading: "SEO Step",
                text: "This is just placeholder text for SEO"
            }}
        >
            <SEOForm {...props} />
        </StepContainer>
    );
}

export default SEO;