import React from 'react';

import {
    FormControl,
    Flex,
    Box,
    Heading,
    IconButton,
} from "@chakra-ui/react";

import { CheckIcon, WarningTwoIcon, InfoIcon } from "@chakra-ui/icons";

const FormContainer = (props) => {

    const { toggleCompleted, getForm, step, steps, setGlobalFormInfo, formInfo } = props;

    const globalForm = getForm(props.formName);
    const stepName = Object.keys(steps)[step];

    const completed = globalForm?.completed;

    const handleCustomInfo = () => {
        if(formInfo) {
            setGlobalFormInfo(formInfo)
        } else {
            setGlobalFormInfo(false);
        }
    }

    return (
        <Flex maxW={1200} margin="auto" position="relative">
            <Box flex="1" p={10} borderRadius={10} boxShadow="base">
            <IconButton
                position="absolute"
                top={"1px"}
                right={"60px"}
                colorScheme={"blue"}
                aria-label="Form Part Info"
                size="lg"
                borderBottomRadius={50}
                borderTopRightRadius={10}
                boxShadow="base"
                onClick={handleCustomInfo}
                icon={<InfoIcon />}
            />
            <IconButton
                position="absolute"
                top={"1px"}
                right={"1px"}
                variant={completed ? "solid" : "ghost"}
                colorScheme={completed ? "green" : "red"}
                aria-label="Form Part Completed"
                size="lg"
                borderBottomRadius={50}
                borderTopRightRadius={10}
                boxShadow="base"
                icon={completed ? <CheckIcon /> : <WarningTwoIcon />}
                onClick={() => toggleCompleted(stepName, props.formName)}
            />

                <Heading as="h2" size="lg" fontWeight={"normal"}>{props.formName}</Heading>
                <Heading as="h3" size="md" fontWeight={"normal"}>{props.subtext ? (`(${props.subtext})`) : undefined}</Heading>
                <br />
                <FormControl>
                    {props.children}
                </FormControl>
            </Box>
        </Flex>
    );
}

export default FormContainer;