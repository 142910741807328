import React, { useState, useEffect } from "react";

import StepContainer from "../components/StepContainer.jsx";

import WebsiteShouldSay from "../components/forms/WebsiteShouldSay.jsx";
import ContentStrategy from "../components/forms/ContentStrategy.jsx";

import Icon from "../images/illustrations/post-it-2.svg";

const Information = (props) => {
    
    return (
        <StepContainer {...props}
            formInfo={{
                image: Icon,
                heading: "Information architecture",
                text: "This is where we identify and define all of your site's content and functionality and how it will be created."
            }}
        >
            <WebsiteShouldSay {...props} />
            <br />
            <ContentStrategy {...props} />
        </StepContainer>
    );
}

export default Information;