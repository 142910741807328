import React from 'react';

import FormContainer from "../FormContainer";
import TextareaList from "../TextareaList";

import {
    FormLabel,
    Textarea
} from "@chakra-ui/react";


const SuccessCriteria = (props) => {
    const formName = "Measurable Success Criteria";
    const globalForm = props.getForm(formName);

    return (
        <FormContainer
        formName={formName}
            subtext={"What measurable criteria can we use to show achievement of your business objectives?"}
        {...props}
        >
            <TextareaList cols={1} opts={[
                "How many enquiries does your current website generate per month?",
                "How much revenue does your current site generate per month?",
                "How well does the current site rank in search engines?",
                "What do you consider to be a conversion on your current site?",
                "What feedback do key stakeholders/users have on the current website?",
                "How will future enquiries and conversions be recorded?",
                "Who will be responsible for tracking search engine ranking improvements?",
                "How will CRM signups be tracked?",
            ]} formName={formName} {...props}/>

        </FormContainer>
    );
}

export default SuccessCriteria;