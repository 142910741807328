import React from 'react';

import FormContainer from "../FormContainer";

import TextInputList from "../TextInputList";

import {
    Grid,
    Box,
} from "@chakra-ui/react";

import SVG from "../SVG";

import Icon1 from "../../images/illustrations/marketing-target-3.svg";
import Icon2 from "../../images/illustrations/tracking-customers-2.svg";


const UserObjectives = (props) => {
    const formName = "User Objectives";
    const globalForm = props.getForm(formName);

    const formData = globalForm.data || {
        groups: [],
        goals: []
    };

    const updateFormData = (dataKey, dataVals) => {
        formData[dataKey] = dataVals;
        props.setDataCompleted(
            formName,
            Object.values(formData).filter(_=>_.length).length === Object.keys(formData).length,
            formData
        );
        props.reload();
    }

    return (
        <FormContainer
        formName={formName}
        formInfo={{
            //image: Icon3,
            heading: "Additional Information",
            text: "A few final items are needed for housekeeping before we wrap things up.",
            body: "Test",
        }}
        // subtext={"How will we create and collate our content?"}
        {...props}
        >

            <Grid templateColumns={"1fr 2fr"}>
                <Box>
                    <SVG src={Icon1} width="100%"/>
                </Box>
                <Box paddingLeft={10}>
                    <TextInputList
                        {...props}
                        heading={"User groups"}
                        text={"How might we identify the different user groups?"}
                        btnText={"Add user group"}
                        data={formData.groups}
                        onChange={(dataVals) => updateFormData("groups", dataVals)}
                    />
                </Box>
            </Grid>
            <Grid templateColumns={"1fr 2fr"}>
                <Box>
                    <SVG src={Icon2} width="100%"/>
                </Box>
                <Box paddingLeft={10}>
                    <TextInputList
                        {...props}
                        heading={"User goals"}
                        text={"Who are the target user groups and what does each user group want to achieve from the website?"}
                        btnText={"Add goal"}
                        data={formData.goals}
                        onChange={(dataVals) => updateFormData("goals", dataVals)}
                        onRemove={(itemId) => console.log(itemId)}
                    />
                </Box>
            </Grid>

        </FormContainer>
    );
}

export default UserObjectives;