import React from 'react';

import FormContainer from "../FormContainer";
import TextareaList from "../TextareaList";

import {
    FormLabel,
    Textarea
} from "@chakra-ui/react";


const Contacts = (props) => {
    const formName = "Contacts";
    const globalForm = props.getForm(formName);

    return (
        <FormContainer
        formName={formName}
        subtext={"Are there any critical behaviours (personnel who may cause blocks in the project timeline) that we need to be aware of?"}
        {...props}
        >
            <TextareaList cols={1} opts={[
                "Who will be responsible for managing the project on the client side?",
                "What will be the preferred methods of communication for this project?",
            ]} formName={formName} {...props}/>

        </FormContainer>
    );
}

export default Contacts;