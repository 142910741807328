import React, { useState, useEffect } from "react";

import {
    SettingsIcon,
    DeleteIcon
} from '@chakra-ui/icons'

import {
    Flex,
    Box,
    Button,
    HStack,
    Text,
    Heading,
    Spacer,
    Avatar,
    WrapItem,
    Progress,
    Badge,
    Fade,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    IconButton,
    Portal,
    Link,
    Tooltip,
    Icon,
    useClipboard,
} from "@chakra-ui/react";

import { useApolloClient } from '@apollo/client';

import { SAVE_STRATEGY_STAGE } from "../apollo/queries";

import { Link as GatsbyLink } from "gatsby"

import { CopyIcon, MinusIcon } from "@chakra-ui/icons";

import { MdMoreHoriz } from "react-icons/md";

const StrategyCard = (props) => {
    const client = useApolloClient();
    const { strategy, loadStrategy, isLoading, duration, isAdmin, stage } = props;

    const [isShowing, setShow] = useState(false);

    useEffect(() => {
        setShow(true);
        return () => setShow(false);
    }, []);

    const changeStage = (strategy, strategyStage) => {
        client.mutate({
            mutation: SAVE_STRATEGY_STAGE,
            variables: { strategyStage, id: strategy.databaseId }
        }).then((res) => {
            props.refetch();
        }).catch(e => {
            console.log(e);
        });
    }

    const documentation = "documentation?id=" + strategy.databaseId;

    const [value, setValue] = useState(document.location + documentation)
    const { hasCopied, onCopy } = useClipboard(value);

    return (
        <WrapItem>
            <Fade in={isShowing} transition={{ enter: {duration}, exit: {duration} }}>
                <Box
                    w="450px"
                    boxShadow={"base"}
                    borderRadius={5}
                    padding={8}
                    opacity={stage.label === "lost" ? 1 : 1}
                    // borderWidth={5}
                    borderColor={stage.scheme + ".500"}
                    bg={"white"}
                    transition={"all 0.2s"}
                    position="relative"
                    zIndex={0}
                    _hover={stage.label !== "lost" ? {
                        transform: "translateY(-10px)",
                        boxShadow: "xl",
                        zIndex: 2
                    }:{}}
                >
                    {isAdmin && stage.label !== "lost" && (
                    <Menu autoSelect={false}>
                        <MenuButton
                            as={IconButton}
                            aria-label="Options"
                            icon={<MdMoreHoriz />}
                            variant="ghost"
                            fontSize={"xl"}
                            right={0} top={0} position="absolute"
                        />
                        <Portal>
                            <MenuList>
                                <MenuItem isDisabled={true}>ID: {strategy.databaseId}</MenuItem>
                                {stage.label === "with_client" && <MenuItem icon={<MinusIcon />} onClick={() => changeStage(strategy, "internal_review")}>
                                    Back to review
                                </MenuItem>}
                                <MenuItem icon={<DeleteIcon />} onClick={() => changeStage(strategy, "lost")}>
                                    Remove
                                </MenuItem>
                            </MenuList>
                        </Portal>
                    </Menu>
                    )}


                    <Flex mb={4}>
                        <Heading size="md">{strategy.title}</Heading>
                        <Spacer />
                        <Box>
                            <Badge variant={"outline"} colorScheme={stage.scheme}>
                                {stage.label ? (stage.label.split('_').join(' ')) : "Not started"}
                            </Badge>
                        </Box>
                    </Flex>
                    <Progress value={strategy.progress || 0} size="md" colorScheme={stage.scheme} mb={3}/>
                    {/* <Text>
                        This is a description of the strategy, or maybe some sort of indicator of progress.
                        This is a description of the strategy, or maybe some sort of indicator of progress.
                    </Text> */}
                    <Flex mt={6} align="center">
                        {stage.label !== "lost" && (
                            <>
                            {stage.label !== "with_client" && <Button
                                isLoading={strategy.id === isLoading}
                                colorScheme={stage.scheme}
                                size={"md"}
                                onClick={() => loadStrategy(strategy)}
                                // rightIcon={<Icon as={stage.icon} />}
                            >
                                {stage.action}
                            </Button>}
                            <Link
                                ml={2}
                                fontSize={14}
                                textDecoration="underline"
                                as={GatsbyLink}
                                to={documentation}
                                replace={false}
                            >
                                Documentation
                            </Link>
                            <Tooltip label="Copied" placement="top" isOpen={hasCopied} hasArrow>
                                <IconButton size="xs" m="4px 5px 0" icon={<CopyIcon />} variant="outline" onClick={onCopy}/>
                            </Tooltip>
                            </>
                        )}
                        <Spacer />
                        <Box>
                            <HStack spacing={3}>
                                <Text fontSize="sm">By {strategy.author.node.firstName}</Text>
                                <Avatar
                                    size="sm"
                                    name={strategy.author.node?.firstName + " " + strategy.author.node?.lastName}
                                    src={strategy.author.node?.avatar.url}
                                />
                            </HStack>
                            
                        </Box>
                    </Flex>
                </Box>
            </Fade>
        </WrapItem>
    );
}

export default StrategyCard;