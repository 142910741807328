import React, { useState } from 'react';
import uniqid from 'uniqid';

import {
    FormLabel,
    Textarea,
    FormControl,
    Grid,
    Box
} from "@chakra-ui/react";


const TextareaList = (props) => {

    const { getForm, setDataCompleted } = props;

    const [changed, setChanged] = useState(false);

    const formName = props.formName;

    const globalForm = getForm(formName);

    const formData = globalForm?.data || Array.from({length: props.opts.length}).fill("");

    const updateCompleted = (i, e) => {
        formData[i] = e.target.value;
        let completed = true;

        formData.forEach(item => {
            if(item.trim() === "") {
                completed = false;
            }
        })

        if(globalForm.completed !== completed) {
            setDataCompleted(formName, completed, formData);
        }

        setChanged(true);
    }

    const updateFormData = () => {
        if(changed) {
            props.setFormData(formName, formData)
            setChanged(false);
        }
    }

    return (
        <Grid gap={4}
            templateColumns={props.cols ? `repeat(${props.cols}, 1fr)` : null}
            templateRows={props.rows ? `repeat(${props.rows}, 1fr)` : null}
        >
            {(
                props.opts.map((opt, i) => {
                    return (
                        <FormControl key={opt}>
                            <Box>
                                <FormLabel width="100%" fontWeight="bold">{opt}</FormLabel>
                                
                                <Textarea
                                    defaultValue={formData[i]}
                                    placeholder="Type response here.."
                                    onChange={e => updateCompleted(i, e)}
                                    onBlur={() => updateFormData()}
                                />
                            </Box>
                        </FormControl>
                    );

                })
            )}
        </Grid>
    );
}


export default TextareaList;