import React, { useState, useEffect } from 'react';
import {
    Box,
    Fade,
    Progress,
    Grid,
    Image,
    Text,
    Divider,
    Heading,

	useRadioGroup,
} from "@chakra-ui/react";
import { useQuery } from '@apollo/client';
import { ALL_TEMPLATES } from '../apollo/queries.js';

import RadioCard from "./RadioCard.jsx";

const TemplateSelect = (props) => {

    const { sections, duration } = props;

    // console.log(sections);

	const [isShowing, setShow] = useState(false);

    useEffect(() => {
        setShow(true);
        return () => setShow(false)
    }, []);

	const realTemplates = (data) => {
		const templates = data?.wireframeTemplates.nodes.map(s => {
			s = {
				...s,
				...s.wireframeTemplateData
			}

			delete s.wireframeTemplateData;

            s.templateStructure = s.templateStructure.map(sectionData => {
                return sectionData.section;
            }).flat().map(section => {
                console.log(section);
                return sections[section.slug];
            });

			return s;
		});

		return templates;
	}


	const { loading, error, data } = useQuery(ALL_TEMPLATES, {

	});

	const templates = realTemplates(data);


	const { getRootProps, getRadioProps } = useRadioGroup({
		name: "template-select",
		onChange: selectedId => {
            const [selectedTemplate] = templates.filter(t => t.id === selectedId);
            props.setSelectedTemplate(selectedTemplate);
		},
	})
  
	const group = getRootProps();

	return (
		<Box p={10} marginTop={2} borderWidth={1}>
			<Fade in={loading} transition={{ enter: { duration: 0 }, exit: { duration: 0 } }}>
				{loading && <Progress size="xs" isIndeterminate colorScheme={"purple"}/>}
			</Fade>
			<Fade in={error} transition={{ enter: {duration}, exit: {duration} }}>
				{error && <p>Error: ${error.message}</p>}
			</Fade>
			<Fade in={!loading && templates} transition={{ enter: {duration}, exit: {duration} }}>
                <Heading size={"md"} textAlign={"center"}>Templates</Heading>
                <Divider />
                <br />
                <Grid templateColumns="repeat(2, 1fr)" gap={3} {...group}>
                    {templates && templates.map((template) => {
                        const radio = getRadioProps({ value: template.id })
                        return (
                            <RadioCard key={template.id} {...radio} radioType={"template"}>
                                <Text fontSize={14} textAlign="center">
                                {template.title}
                                </Text>
                            {
                                template.templateStructure && template.templateStructure.map((section, i) => {
                                    const sectionKey = i + "-" + section.slug;
                                    return (
                                        <Image
                                            src={section.image.sourceUrl || section.image}
                                            key={sectionKey}
                                            ignoreFallback={true}
                                        ></Image>
                                    )
                                })
                            }
                            </RadioCard>
                        )
                    })}
                    
                </Grid>
			</Fade>
		</Box>
	);
}

export default TemplateSelect;