const globalData = {
	"step": 0,
	"steps": {
		"Overview": {
			"data": { "notes": "", "locked": false },
			
			"Strategy overview": { "completed": true, "data": false }
		},
		"Business Information": {
			"data": { "notes": "", "locked": false },
			
			"Business Information": { completed: false, "data": false, }
		},
		"Business Objectives": {
			"data": { "notes": "", "locked": true },

			"Your Objectives": { "completed": false, "data": false },
			"Measurable Success Criteria": { "completed": false, "data": false },
			"Call to Actions": { "completed": false, "data": false }
		},
		"User Objectives": {
			"data": { "notes": "", "locked": true },
			
			"User Objectives": { "completed": false, "data": false },
			"User Needs": { "completed": false, "data": false },
			"Competitors & Inspiration": { "completed": false, "data": false }
		},
		"Assets": {
			"data": { "notes": "", "locked": true },

			"Design & Social Media Assets": { "completed": false, "data": false },
			"Video & Photography": { "completed": false, "data": false }
		},
		"Information": {
			"data": { "notes": "", "locked": true },

			"What do we want the website to say?": { "completed": false, "data": false },
			"Content Strategy": { "completed": false, "data": false }
		},
		"SEO": {
			"data": { "notes": "", "locked": true },

			"SEO": { "completed": false, "data": false }
		},
		"Additional Information": {
			"data": { "notes": "", "locked": true },

			"Additional Information": { "completed": false, "data": false },
			"Contacts": { "completed": false, "data": false },
			"Unanswered Questions": { "completed": false, "data": false }
		},
		"Page Builder": {
			"data": { "locked": true }
		},
		"Extras": {
			"data": { "locked": false }
		}
	},
	"page": false,
    "pages": [],
	"name": "Unnamed Strategy",
};

export {
	globalData
};