import { GrInProgress } from "react-icons/gr";
import { GiHourglass } from "react-icons/gi";
import { VscPreview, VscTrash } from "react-icons/vsc";
import { RiUserReceived2Line, RiUserHeartLine } from "react-icons/ri";
import { BsCardChecklist } from "react-icons/bs";

const strategyStages = {
    "in_progress": {
        heading: "In Progress",
        strategies: [],
        action: "Continue",
        icon: GiHourglass,
        scheme: "purple"
    },
    "internal_review": {
        heading: "Internal Review",
        strategies: [],
        action: "Review",
        icon: VscPreview,
        scheme: "blue"
    },
    "with_client": {
        heading: "With Client",
        strategies: [],
        action: "View",
        icon: RiUserReceived2Line,
        scheme: "pink"
    },
    "accepted": {
        heading: "Accepted",
        strategies: [],
        action: "View",
        icon: RiUserHeartLine,
        scheme: "teal"
    },
    "completed": {
        heading: "Completed",
        strategies: [],
        action: "View",
        icon: BsCardChecklist,
        scheme: "green"
    },
    "lost": {
        heading: "Lost / Removed",
        strategies: [],
        action: "View",
        icon: VscTrash,
        scheme: "red"
    },
};

export default strategyStages;