import React, { useState, useEffect } from "react";

import {
    Flex,
    Box,
} from "@chakra-ui/react";

import Header from "../components/Header.jsx";
import Sidebar from "../components/Sidebar.jsx";

import Overview from "../steps/Overview.jsx";
import BusinessInformation from "../steps/BusinessInformation.jsx";
import BusinessObjectives from "../steps/BusinessObjectives.jsx";
import UserObjectives from "../steps/UserObjectives.jsx";
import Assets from "../steps/Assets.jsx";
import Information from "../steps/Information.jsx";
import SEO from "../steps/SEO.jsx";
import AdditionalInformation from "../steps/AdditionalInformation.jsx";
import PageBuilder from "../steps/PageBuilder.jsx";
import Extras from "../steps/Extras.jsx";


const StrategySession = (props) => {

    const [commandPressed, setCommandPressed] = useState(false);

    const {
        cloneSection,
        duration,
        getCurrentPageFromId,
        page,
        pages,
        reload,
        save,
        sections,
        selectedSection,
        setPages,
        setSections,
        setSelectedSection,
        step,
        changePage
    } = props;


    let commands = {
        // 83: () => save(),
        // 70: () => {
        //     if(step === 7) {
        //         document.querySelector("#section-search-input").focus();
        //     }
        // }
    };

    const handleKeyDown = (e) => {
        if(e.keyCode === 91 || (e.keyCode === 17 && e.ctrlKey)) {
            e.preventDefault();
            setCommandPressed(true);
        }

        if(commandPressed && commands[e.keyCode]) {
            e.preventDefault();
            commands[e.keyCode]();
            // commandPressed = false;
        }
    }

    const handleKeyUp = (e) => {
        if(e.keyCode === 91 || (e.keyCode === 17 && e.ctrlKey)) {
            e.preventDefault();
            setCommandPressed(false);
        }
    }

    return (
        <Box height="100vh" onKeyDown={handleKeyDown} onKeyUp={handleKeyUp}>
            <Header {...props}/>
            <Flex height="calc(100vh - 60px)">
                <Box flex={2} boxShadow="xl">
                    <Sidebar {...props}/>
                </Box>
                
                {step === 0 && (<Overview {...props} />)}
                {step === 1 && (<BusinessInformation {...props}/>)}
                {step === 2 && (<BusinessObjectives {...props}/>)}
                {step === 3 && (<UserObjectives {...props}/>)}
                {step === 4 && (<Assets {...props}/>)}
                {step === 5 && (<Information {...props} />)}
                {step === 6 && (<SEO {...props}/>)}
                {step === 7 && (<AdditionalInformation {...props}/>)}

                {step === 8 && (
                <PageBuilder
                    pages={pages}
                    setPages={setPages}
                    page={page}
                    changePage={changePage}
                    getCurrentPageFromId={getCurrentPageFromId}
                    duration={duration}
                    sections={sections}
                    setSections={setSections}
                    selectedSection={selectedSection}
                    setSelectedSection={setSelectedSection}
                    cloneSection={cloneSection}
                    reload={reload}
                    save={save}
                />
                )}
                
                {step === 9 && (
                    <Extras {...props}/>
                )}
            </Flex>
        </Box>
    );
}


export default StrategySession;