import React, { useState } from 'react';

import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    ModalCloseButton,
    Button,
    Text,
    Image,
    Heading,
    OrderedList,
    ListItem,
    Link,
    Divider,
    useDisclosure,
    Icon,
    Flex,
    Spacer,
    Box,
    Center
} from "@chakra-ui/react";

import TextInputList from "./TextInputList";

import { ExternalLinkIcon } from '@chakra-ui/icons';

import { ImUndo } from "react-icons/im"

const SectionPreviewEdit = (props) => {
    const { section, setSection, updateSection, sections } = props;
    const { isOpen, onOpen, onClose } = useDisclosure()

    const handleClose = () => {
        setSection(false);
    }

    const resetToDefault = () => {
        const defaultSection = sections[section.slug];
        updateSection(section.id, {
            ...defaultSection
        });
        props.reload();
    }

    const updateRequests = (requests) => {
        updateSection(section.id, {
            requests,
            highlighted: requests.length ? true : false
        });
    }

    return (
        <Modal isOpen={section} onClose={handleClose} size="2xl">
            {section && (<>
            <ModalOverlay />
            <ModalContent>
            <ModalHeader>{section.title}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
                {/* Description */}
                {section.description && (<>
                <Text>{section.description}</Text>
                <br />
                </>)}

                {/* Features */}
                {section.features && section.features.length > 0 && (<>
                <Heading size={"sm"}>Features</Heading>
                <OrderedList mb={3}>
                    {section.features.map(feature => {
                        return feature.name && <ListItem key={feature.name} fontSize={"sm"}>{feature.name}</ListItem>
                    })}
                </OrderedList>
                <br />
                </>)}

                {/* Limitations */}
                {section.limitations && section.limitations.length > 0 && (<>
                <Heading size={"sm"}>Limitations</Heading>
                <OrderedList>
                    {section.limitations.map(limitation => {
                        return limitation.name && <ListItem key={limitation.name} fontSize={"sm"}>{limitation.name}</ListItem>
                    })}
                </OrderedList>
                <br />
                </>)}

                {/* Web Examples */}
                {section.webExamples && section.webExamples.length > 0 && (<>
                <Heading size={"sm"}>Web Examples</Heading>
                <OrderedList>
                    {section.webExamples.map(example => {
                        return (
                            <ListItem key={example.url} fontSize={"sm"}>
                                <Link href={example.url} isExternal>
                                {example.url}<ExternalLinkIcon mx="2px" />
                                </Link> 
                            </ListItem>
                        );
                    })}
                </OrderedList>
                <br />
                </>)}
                <Divider />
                <br />
                {/* Custom Requests */}
                <Heading size={"sm"} mb={2}>Additional Requests</Heading>
                <TextInputList
                    type="textarea"
                    placeholder="Type additional request here..."
                    btnText="Add Request"
                    data={section.requests}
                    onChange={updateRequests}
                />
                <br />

                {/* Image */}
                <Image
                src={section.image.sourceUrl || section.image}
                alt={`Section ${section.id} Image`}
                ></Image>
            </ModalBody>

            <ModalFooter>
                <Flex w="100%">
                    <Center>
                        <Text fontWeight="bold" as="span">    
                            Estimated Time: 
                        </Text>
                        <Text as="span" ml={2}>
                            {(section.designTime || 0) + (section.developmentTime || 0)} hours
                        </Text>
                    </Center>
                    <Spacer />
                    <Box>
                        <Button leftIcon={<Icon as={ImUndo} />} mr={4} colorScheme="blue" onClick={() => resetToDefault()}>Reset to default</Button>
                        <Button colorScheme="purple" onClick={handleClose}>Save Section</Button>
                    </Box>
                </Flex>
            </ModalFooter>
            </ModalContent>
            </>)}
        </Modal>
    );
}


export default SectionPreviewEdit;