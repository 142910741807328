import React, {useState, useEffect} from 'react';
import uniqid from 'uniqid';

import { AddIcon } from '@chakra-ui/icons'

import {
	Button,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
	Stack,
	Center,
	Input,
	HStack,
	FormControl,
	Divider,

	useDisclosure,
	useRadioGroup,
} from "@chakra-ui/react";

import RadioCard from "./RadioCard.jsx";
import TemplateSelect from "./TemplateSelect.jsx";

import { addNodeUnderParent } from "react-sortable-tree";

const blankPage = {
	title: "Blank Page",
	sections: [],
	children: [
  
	],
};

const defaultSections = [
	// "header-1",
	// "footer-1"
];



const NewPage = (props) => {
	const { pages, setPages, changePage, cloneSection  } = props;

    const { isOpen, onOpen, onClose } = useDisclosure();

	const initialRef = React.useRef();

	const [pageTitle, setPageTitle] = useState("");
	const [showingTemplates, setShowingTemplates] = useState(false);
	const [selectedTemplate, setSelectedTemplate] = useState(false);

	useEffect(() => {
		onOpen();
	}, []);


	const handleClose = () => {
		onClose();
		props.setAddParentId(false);
	}

    const addNewPage = (sections) => {
		const parentId = props.addParentId === -1 ? null : props.addParentId;

		const newPage = JSON.parse(JSON.stringify(blankPage));
		newPage.id = uniqid();
		newPage.title = pageTitle || "Unnamed Page";
		newPage.sections = sections;

		setPages(addNodeUnderParent({
			treeData: pages,
			newNode: newPage,
			getNodeKey: _=>_.node.id,
			parentKey: parentId,
			expandParent: true,
		}).treeData);
  
		changePage(newPage.id);
		handleClose();
    }

	const handleBlankPage = () => {
		const sections = defaultSections.map(slug => {
			return cloneSection(slug);
		}).filter(Object)
		addNewPage(sections);
	}

	const handleTemplatePage = () => {
		const sections = selectedTemplate.templateStructure.map(sectionData => {
			return cloneSection(sectionData.slug);
		}).filter(Object)
		addNewPage(sections);
	}

	const handleAddPage = () => {
		if(showingTemplates) {
			//if template selected
			handleTemplatePage();

			//else show error message, must select template
		} else {
			handleBlankPage();
		}
	}

	const options = ["Blank Page", "Select Template"];
	const { getRootProps, getRadioProps } = useRadioGroup({
		name: "framework",
		defaultValue: "Blank Page",
		onChange: val => {
			setShowingTemplates(val === "Select Template");
		},
	})
  
	const group = getRootProps();

    return (
      <>
        <Modal
			closeOnOverlayClick={false}
			size={"3xl"}
			isOpen={isOpen}
			onClose={handleClose}
			autoFocus={true}
			initialFocusRef={initialRef}
			returnFocusOnClose={false}
		>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Create New Page</ModalHeader>
            <ModalCloseButton />
			<Divider />
            <ModalBody pb={"100px"} pt={"100px"}>
				<FormControl maxWidth={530} margin="auto">
					Page Title:
					<Input
						ref={initialRef}
						placeholder="i.e. Contact Us"
						size="lg"
						defaultValue={pageTitle}
						onInput={e => setPageTitle(e.target.value)}
						onKeyDown={e => e.key === 'Enter' && handleAddPage()}
					/>
				</FormControl>

				<br />

				<Center>
					<HStack {...group}>
						{options.map((value) => {
						const radio = getRadioProps({ value })
						return (
							<RadioCard key={value} {...radio}>
							{value}
							</RadioCard>
						)
						})}
					</HStack>

				</Center>

				{showingTemplates && (
					<TemplateSelect {...props} setSelectedTemplate={setSelectedTemplate}/>
				)}

            </ModalBody>
  
			<Divider />
            <ModalFooter>
				<Stack spacing={4} direction="row" align="center">
					<Button onClick={handleClose}>Cancel</Button>
					<Button rightIcon={<AddIcon/>} colorScheme={"purple"} onClick={handleAddPage}>Create Page</Button>
				</Stack>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    )

}

export default NewPage;