import React, { useState, useEffect } from "react";

import StepContainer from "../components/StepContainer.jsx";

import Icon from "../images/illustrations/treasure-chest-2.svg";

import DesignSocialAssets from "../components/forms/DesignSocialAssets.jsx";
import VideoPhotography from "../components/forms/VideoPhotography.jsx";

const Assets = (props) => {
    return (
        <StepContainer {...props}
            formInfo={{
                image: Icon,
                heading: "Your design assets",
                text: "In this section we'll record what design and social media assets we'll be using to build your new website."
            }}
        >
            <DesignSocialAssets {...props}/>
            <br />
            <VideoPhotography {...props}/>
        </StepContainer>
    );
}

export default Assets;