import React, { useState } from 'react';
import FocusLock from "react-focus-lock"

import {
    FormLabel,
    FormControl,
    Stack,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverArrow,
    PopoverCloseButton,
    Button,
    ButtonGroup,
    Input,
    useDisclosure
} from "@chakra-ui/react";

const TextInput = React.forwardRef((props, ref) => {
    return (
        <FormControl>
            <FormLabel htmlFor={props.id}>{props.label}</FormLabel>
            <Input ref={ref} id={props.id} {...props} />
        </FormControl>
    )
})

const CreateStrategy = (props) => {
    const { createStrategy, createLoading } = props;
    
    const { onOpen, onClose, isOpen } = useDisclosure();

    const [strategyName, setStrategyName] = useState("Unnamed Strategy");
    const firstFieldRef = React.useRef(null);

    return (
        <Popover
            isOpen={isOpen}
            initialFocusRef={firstFieldRef}
            onOpen={onOpen}
            onClose={onClose}
            placement="bottom"
            closeOnBlur={false}
        >
            <PopoverTrigger>
                <Button colorScheme={"purple"}>Start a new strategy</Button>
            </PopoverTrigger>
            <PopoverContent p={5}>
                <FocusLock returnFocus persistentFocus={false}>
                    <PopoverArrow />
                    <PopoverCloseButton />
                    <Stack spacing={4}>
                        <TextInput
                            label="Strategy Name:"
                            id="strategy-name"
                            ref={firstFieldRef}
                            autoComplete="off"
                            data-lpignore="true"
                            data-form-type="text"
                            defaultValue={strategyName}
                            isDisabled={createLoading}
                            onChange={(e) => setStrategyName(e.target.value)}
                            onKeyDown={e => e.key === 'Enter' && createStrategy(strategyName)}
                        />
                        <ButtonGroup d="flex" justifyContent="flex-end">
                            <Button variant="outline" onClick={onClose}>
                            Cancel
                            </Button>
                            <Button
                                isLoading={createLoading}
                                colorScheme="purple"
                                onClick={() => createStrategy(strategyName)}
                            >
                            Create
                            </Button>
                        </ButtonGroup>
                    </Stack>
                </FocusLock>
            </PopoverContent>
        </Popover>
    );
}

export default CreateStrategy;