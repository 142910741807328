import React, { useState, useEffect } from "react";

import StepContainer from "../components/StepContainer.jsx";

import AdditionalInfo from "../components/forms/AdditionalInformation.jsx";
import Contacts from "../components/forms/Contacts.jsx";
import UnansweredQuestions from "../components/forms/UnansweredQuestions.jsx";

import Icon from "../images/illustrations/search-user-1.svg";

const AdditionalInformation = (props) => {

    return (
        <StepContainer {...props}
            formInfo={{
                image: Icon,
                heading: "Additional Information Step",
                text: "This is just placeholder text for additional information"
            }}
        >
            <AdditionalInfo {...props}/>
            <br />
            <Contacts {...props}/>
            <br />
            <UnansweredQuestions {...props}/>
        </StepContainer>
    );
}

export default AdditionalInformation;