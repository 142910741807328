import React from 'react';

import FormContainer from "../FormContainer";

import TextInputList from "../TextInputList";

import {
    FormLabel,
    Textarea,
    Table,
    Thead,
    Tr,
    Th,
    Td,
    Tbody,
    Menu,
    MenuButton,
    MenuList,
    MenuOptionGroup,
    MenuItemOption,
    Button,
    UnorderedList,
    ListItem,
    Box,
    Alert,
    AlertIcon
} from "@chakra-ui/react";


const UserNeeds = (props) => {
    const formName = "User Needs";
    const globalForm = props.getForm(formName);

    const objectivesForm = props.getForm("User Objectives").data;
    const userGroups = objectivesForm?.groups;
    const userGoals = objectivesForm?.goals;
    
    let formData = globalForm.data || {
        groups: userGroups,
        needs: []
    };

    const setUserGoals = (userGroup, userGoalValues) => {
        formData.groups = formData.groups.map(uGroup => {
            if(uGroup.id === userGroup.id) {
                uGroup.goals = userGoalValues;
            }

            return uGroup;
        });
    }

    const updateUserAllData = () => {

        let completed = true;
        formData.groups.forEach(group => {
            if(!group.goals || (group.goals && group.goals.length < 1)) {
                completed = false;
            }
        });

        if(formData.needs.length < 1) {
            completed = false;
        }

        props.setDataCompleted(formName, completed, formData);
        props.reload();
    }

    const setUserNeeds = (userNeeds) => {
        formData.needs = userNeeds;
        updateUserAllData();
    }

    return (
        <FormContainer
        formName={formName}
        subtext={"What does each user group need in order to achieve what they want?"}
        {...props}
        >
        {(!userGroups || userGroups.length < 1) && (
            <Alert status="warning">
                <AlertIcon />
                Atleast one user group is required to complete this step.
            </Alert>
        )}
        {(!userGoals || userGoals.length < 1) && (
            <Alert status="warning">
                <AlertIcon />
                Atleast one user goal is required to complete this step.
            </Alert>
        )}
        {userGroups && userGroups.length > 0 && userGoals && userGoals.length > 0 && (
            <Box>
                <Table size="sm" borderWidth={1}>
                    <Thead>
                        <Tr>
                            <Th>User</Th>
                            <Th>Goals</Th>
                            <Th>Needs</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {(userGroups && userGroups
                                .map((userGroup, userGroupIndex) => {

                                const selectedUserGoals = (formData.groups.filter(ug => ug.id === userGroup.id)[0] || {}).goals || [];

                                return (
                                    <Tr key={userGroup.id}>
                                        <Td fontWeight="700" maxW="150px">{(userGroup.value || userGroup.text)}</Td>
                                        <Td>
                                            <UnorderedList>
                                            {selectedUserGoals.map((userGoal, i) => (
                                                <ListItem key={userGoal + "-" + i} mb="6px">
                                                    {userGoal}
                                                </ListItem>
                                            ))}
                                            </UnorderedList>
                                            <Menu autoSelect={false} closeOnSelect={false} isLazy={true} colorScheme={"purple"} onClose={() => updateUserAllData()}>
                                                <MenuButton as={Button} size={"xs"} colorScheme={"purple"} m="10px 0">
                                                    Select Goals
                                                </MenuButton>
                                                <MenuList minWidth="240px" >
                                                    <MenuOptionGroup
                                                        defaultValue={selectedUserGoals}
                                                        onChange={userGoalValues => setUserGoals(userGroup, userGoalValues)}
                                                        title={"Goals for " + (userGroup.value || userGroup.text)} type="checkbox">
                                                        {
                                                            userGoals.map(userGoal => {
                                                                return (
                                                                    <MenuItemOption
                                                                        key={userGoal.id}
                                                                        value={(userGoal.value || userGoal.text)}
                                                                        // isChecked={isChecked}
                                                                    >{(userGoal.value || userGoal.text)}
                                                                    </MenuItemOption>
                                                                )
                                                            })
                                                        }
                                                    </MenuOptionGroup>
                                                </MenuList>
                                            </Menu>
                                        </Td>
                                        {userGroupIndex === 0 && (
                                        <Td rowSpan={userGroups.length} borderLeft="1px solid #edf2f7">
                                            <TextInputList
                                                {...props}
                                                // heading={"User needs"}
                                                // text={"How might we identify the different user groups?"}
                                                btnText={"Add user need"}
                                                data={formData.needs}
                                                onChange={(dataVals) => setUserNeeds(dataVals)}
                                            />
                                        </Td>
                                        )}
                                    </Tr>
                                );
                            })
                        )}
                    </Tbody>
                </Table>
            </Box>
        )}
        </FormContainer>
    );
}

export default UserNeeds;