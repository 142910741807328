import React from 'react';

import {
	Box,
	useRadio,
} from "@chakra-ui/react";

const RadioCard = (props) => {
	const { getInputProps, getCheckboxProps } = useRadio(props);
  
	const input = getInputProps();
	const checkbox = getCheckboxProps();

	return (
	  <Box as="label" height={"100%"}>
		<input {...input} />
		<Box
			height={"100%"}
			{...checkbox}
			cursor="pointer"
			bg={"white"}
			borderWidth={props.radioType === "template" ? "1px" : "3px"}
			borderStyle={props.children === "Blank Page" ? "dashed" : "solid"}
			borderColor={"grey.500"}
			size="lg"
			borderRadius={10}
			_checked={{
				bg: "purple.500",
				color: "white",
				// borderColor: "purple.600",
			}}
			px={props.radioType === "template" ? 2 : 20}
			py={props.radioType === "template" ? 2 : 15}
			>
			{props.children}
		</Box>
	  </Box>
	)
}

export default RadioCard;