import React from 'react';

import { Image } from "@chakra-ui/react";


const SVG = (props) => {
    if(props.src && typeof props.src !== "string") {
        const SVGTag = props.src;
        const propsCopy = {...props};
        delete propsCopy.src;
        return (<SVGTag {...propsCopy}/>);
    } else if(props.src && typeof props.src === "string") {
        return (<Image {...props} src={props.src}/>);
    } else {
        return (<></>);
    }
}

export default SVG;