import React from "react";

import {
	CircularProgress,
	CircularProgressLabel
  } from "@chakra-ui/react";

const StepProgress = (props) => {
    const { progress, size, showColor } = props;

    if(progress) {
        return (
            <CircularProgress
                    size={size || "40px"}
                    value={progress.percent}
                    thickness="5px"
                    color={progress.color}
                    mr={3}
                >
                <CircularProgressLabel color={showColor ? progress.color : "black"}>{progress.percent < 1 ? 0 : progress.percent}%</CircularProgressLabel>
            </CircularProgress>
        );
    } else {
        return <></>
    }
}

export default StepProgress;