import React, { useState, useEffect } from 'react';
import uniqid from "uniqid";
import fetch from 'cross-fetch';
import OAuth2Login from 'react-simple-oauth2-login';
import {
    Button,
    Text,
    useDisclosure
} from "@chakra-ui/react";

import Slickplan_Modal from "./Slickplan_Modal";

const config = {
    client_id: "a9kdsaw53kkfcmdjfhzmya7m7tdsttvjgodm0pem",
    client_secret: "i3ta86awwe26t8c5w65vc7epheb48xcjbvqkqfoe",
    redirect_uri: "http://localhost:8000",
    authorize_uri: "https://slickplan.com/api/v1/authorize",
    token_uri: "https://slickplan.com/api/v1/token",
    scope: [
        "sitemaps_read",
        "sitemaps_write",

        "sitemaps_page_types_read",
        "sitemaps_page_types_write",

        "sitemaps_diagrams_read",
        "sitemaps_diagrams_write",

        "sitemaps_content_read",
        "sitemaps_content_write",

        "user_read"
    ]
};

const Slickplan = (props) => {

    const [slickplanDetails, setSlickplanDetails] = useState(props.steps?.Extras?.data?.slickplanDetails || {});
    const [loadingAuth, setLoadingAuth] = useState(false);

    const { isOpen, onOpen, onClose } = useDisclosure();

    const [shouldOpen, setShouldOpen] = useState(false);

    const handleLogin = () => {
        onOpen();
    }

    const setSlickplanDetailsWrapper = (r, save=false, reload=false) => {
        for(const key in r) {
            slickplanDetails[key] = r[key];
        }
        props.steps.Extras.data.slickplanDetails = slickplanDetails;
        if(save) {
            if(typeof save === "function") {
                props.save(false, save);
            } else {
                props.save(false);
            }
        }
        setSlickplanDetails(slickplanDetails);
        if(reload) {
            props.reload();
        }
    }

    const handleTokenResponse = (r) => {
        setSlickplanDetailsWrapper(r, true);
    }

    const buildURL = (endpoint, params={}) => {
        const url = new URL("https://strategy.embarkagency.com.au/wp/wp-json/api/v1/slickplan-get");
        url.searchParams.set("endpoint", endpoint);
        for(const key in params) {
            url.searchParams.set(key, params[key]);
        }
        return url;
    }

    const refreshToken = async token => {
        const url = "https://strategy.embarkagency.com.au/wp/wp-json/api/v1/slickplan-refresh?token=" + token;
        const req = await fetch(url)
        const res = await req.json();
        handleTokenResponse(res);
    };

    const onSuccess = async response => {
        const auth_url = "https://strategy.embarkagency.com.au/wp/wp-json/api/v1/slickplan-token?code=" + response.code;
        const auth_req = await fetch(auth_url)
        let auth_res = await auth_req.json();

        if(!auth_res.error && auth_res.access_token) {
            const me_url = buildURL("me", {
                access_token: auth_res.access_token
            });
            const me_req = await fetch(me_url);
            const me_res = await me_req.json();
            if(!me_res.error) {
                auth_res = {...auth_res, user: me_res};
            }
        }

        setLoadingAuth(false);
        handleTokenResponse(auth_res);
    };

    const onFailure = response => {
        console.error(response);
        setLoadingAuth(false);
    }

    const handleSlickplan = (authCallback) => {
        if(slickplanDetails.access_token) {
            setShouldOpen(true);
        } else {
            if(typeof authCallback === "function") {
                setLoadingAuth(true);
                authCallback();
            }
        }
    }

    const handleLogoutSlickplan = () => {
        setSlickplanDetailsWrapper({
            access_token: undefined,
            expires_in: undefined,
            refresh_token: undefined,
            scope: undefined,
            token_type: undefined,
            sitemap: undefined,
            user: undefined
        }, true);
    }

    if(shouldOpen && slickplanDetails.access_token) {
        handleLogin();
        setShouldOpen(false);
    }

    return (
        <React.Fragment>
            <OAuth2Login
                authorizationUrl={config.authorize_uri}
                responseType="code"
                clientId={config.client_id}
                clientSecret={config.client_secret}
                redirectUri={config.redirect_uri}
                extraParams={{ state: uniqid(), scope: config.scope.join(" ") }}
                buttonText={slickplanDetails.user ? "Manage Slickplan" : "Login with Slickplan"}
                onSuccess={onSuccess}
                onFailure={onFailure}
                render={({ buttonText, className, onClick }) => (
                <Button
                    colorScheme="orange"
                    bgGradient="linear(to-r, #ff8f02, #ff6b00)"
                    _hover={{ "bg": "#ff5601" }}
                    _active={{ "bg": "#ff5601" }}
                    borderRadius="50px"
                    padding="25px 30px"
                    minWidth="140px"
                    fontWeight="normal"
                    isLoading={loadingAuth}
                    loadingText="Authenticating"
                    onClick={() => handleSlickplan(onClick)}>
                    {buttonText}
                </Button>
                )}
            />
            {slickplanDetails.user && (<>
                <Text display="inline-block" marginLeft={5}>
                    Logged in as <b>{slickplanDetails.user.first_name} {slickplanDetails.user.last_name}</b>
                </Text>
                <Button onClick={handleLogoutSlickplan} size="xs" marginLeft={2} colorScheme="red">Logout</Button>
            </>)}
            <Slickplan_Modal
                isOpen={isOpen}
                shouldOpen={shouldOpen}
                onClose={onClose}
                refreshToken={refreshToken}
                details={slickplanDetails}
                buildURL={buildURL}
                setDetails={setSlickplanDetailsWrapper}
            />
            
        </React.Fragment>
    );
}

export default Slickplan;