import React, { useState, useEffect } from "react";

import StepContainer from "../components/StepContainer.jsx";

import Icon from "../images/illustrations/conversation-businessman-customer-1.svg";

import UserObjectivesForm from "../components/forms/UserObjectives.jsx";
import UserNeedsForm from "../components/forms/UserNeeds.jsx";
import CompetitorInspirationForm from "../components/forms/CompetitorsInspiration.jsx";

const UserObjectives = (props) => {
    return (
        <StepContainer {...props}
            formInfo={{
                image: Icon,
                heading: "Your user's objectives",
                text: "Understanding your user’s objectives will help you to better service their needs and deliver a great web experience."
            }}
        >
            <UserObjectivesForm {...props}/>
            <br />
            <UserNeedsForm {...props}/>
            <br />
            <CompetitorInspirationForm {...props}/>
        </StepContainer>
    );
}

export default UserObjectives;