import React from "react";

const DiscountPrice = (props) => {
    const { discountType, discountVal, totalPrice } = props;

	let discountedTotal = false;
	if(discountType === "percent" && discountVal) {
		discountedTotal = Math.ceil(totalPrice - ((discountVal / 100) * totalPrice));
	} else if(discountType === "fixed" && discountVal) {
		discountedTotal = Math.ceil(totalPrice - discountVal);
	}

    if(discountedTotal) {
        return <>
            <s>${totalPrice}</s>&nbsp;&nbsp;<b>${discountedTotal}</b>
        </>
    } else {
        return <>
            ${totalPrice}
        </>
    }
}

export default DiscountPrice;