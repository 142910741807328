import React, { useState, useEffect } from "react"

import {
  Box,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  InputLeftElement,
  InputGroup,
  Input,
} from "@chakra-ui/react"

import { SearchIcon } from "@chakra-ui/icons";

import { SECTION_CONTENT_TYPES, SECTION_PAGE_TYPES } from '../apollo/queries.js';

import SectionSelect_All from "./SectionSelect_All.jsx";
import SectionSelect_Types from "./SectionSelect_Types.jsx";

const SectionSelect = (props) => {
	const {
		page,
		pages,
		getCurrentPageFromId,
	} = props;

	const [isShowing, setShow] = useState(false);

    useEffect(() => {
        setShow(true);
        return () => setShow(false)
    }, []);

	const [searchInput, setSearchInput] = useState("");

	const pageData = getCurrentPageFromId(pages, page);

	if(pageData) {
		return (
			<Box p={2} h="calc(100vh - 60px)" overflowY="scroll" boxShadow="xl" position="relative">
				
				<InputGroup>
					<InputLeftElement
					pointerEvents="none"
					children={<SearchIcon color="gray.300" />}
					zIndex={-1}
					/>
					<Input
						defaultValue={searchInput}
						type="text"
						placeholder="Filter Sections"
						variant="flushed"
						onChange={e => setSearchInput(e.target.value)}
						id="section-search-input"
					/>
				</InputGroup>

				<br />

				<Tabs
					isFitted={true}
					variant={"line"}
					size={"sm"}
					colorScheme={"purple"}
					isLazy={true}
				>
					<TabList>
						<Tab>All</Tab>
						<Tab>Content</Tab>
						<Tab>Pages</Tab>
					</TabList>

					<TabPanels>
						<TabPanel>
							<SectionSelect_All searchInput={searchInput} {...props}/>
						</TabPanel>

						<TabPanel padding={0}>
							<SectionSelect_Types
								searchInput={searchInput}
								filterQuery={SECTION_CONTENT_TYPES}
								typeName="contentTypes"
								{...props}
							/>
						</TabPanel>

						<TabPanel padding={0}>
							<SectionSelect_Types
								searchInput={searchInput}
								filterQuery={SECTION_PAGE_TYPES}
								typeName="pageTypes"
								{...props}
							/>
						</TabPanel>
					</TabPanels>
				</Tabs>
			</Box>
		);
	} else {
		return <></>
	}
};

export default SectionSelect;