import React from 'react';

import FormContainer from "../FormContainer";

import TextInputList from "../TextInputList";

import {
    Grid,
    Box,
} from "@chakra-ui/react";

import SVG from "../SVG";

import Icon1 from "../../images/illustrations/voice-being-heard-2.svg";

const CallToActions = (props) => {
    const formName = "Call to Actions";
    const globalForm = props.getForm(formName);

    const formData = globalForm.data || {
        conversions: []
    };

    const updateFormData = (dataKey, dataVals) => {
        formData[dataKey] = dataVals;
        props.setDataCompleted(
            formName,
            Object.values(formData).filter(_=>_.length).length === Object.keys(formData).length,
            formData
        );
        props.reload();
    }

    return (
        <FormContainer
        formName={formName}
            //subtext={"What do we want people to do on the site in order to measure success?"}
        {...props}
        >

            <Grid templateColumns={"1fr 2fr"}>
                <Box>
                    <SVG src={Icon1} width="100%"/>
                </Box>
                <Box paddingLeft={10}>
                    <TextInputList
                        {...props}
                        heading={"What is a conversion?"}
                        text={"eg. Purchase product, submit online enquiry, sign up to a newsletter, download a brochure"}
                        btnText={"Add conversion"}
                        data={formData.conversions}
                        onChange={(dataVals) => updateFormData("conversions", dataVals)}
                    />
                </Box>
            </Grid>

        </FormContainer>
    );
}

export default CallToActions;