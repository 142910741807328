import React from 'react'

import { useQuery } from '@apollo/client';
import { ALL_SECTIONS } from '../apollo/queries.js';

import {
  SimpleGrid,
  Progress,
  Fade,
  Alert,
  AlertIcon
} from "@chakra-ui/react";

import SectionCard from "./SectionCard.jsx";

const SectionSelect_All = (props) => {

	const {
		searchInput,

		sections,
		setSections,
		selectedSection,
		setSelectedSection,
		duration,
	} = props;

	const { loading, error, data } = useQuery(ALL_SECTIONS, {
		onCompleted: (allSections) => {
			allSections = realSections(allSections);
			if(!sections && !loading && sectionsData) {
				const dataSections = {};
				sectionsData.forEach(section => {
					dataSections[section.slug] = section;
				});
				setSections(dataSections);
			}
		}
	});

	const realSections = (data) => {
		const sectionsData = data?.sections.nodes.map(s => {
			s = {
				...s,
				...s.sectionData
			}
	
			delete s.sectionData;
			return s;
		})
		.sort((a, b) => {
			if (a.slug < b.slug) return -1;
			if (a.slug > b.slug) return 1;
			return 0;
		}).filter(r => searchInput ? r.title.toLowerCase().includes(searchInput.toLowerCase()) : true);

		return sectionsData;
	}

	const sectionsData = realSections(data);

    return (
        <>
        <Fade in={loading} transition={{ enter: { duration: 0 }, exit: { duration: 0 } }}>
            {loading && <Progress size="xs" isIndeterminate colorScheme={"purple"}/>}
        </Fade>
        <Fade in={error} transition={{ enter: {duration}, exit: {duration} }}>
            {error && <p>Error: ${error.message}</p>}
        </Fade>
        <Fade in={!loading && sectionsData} transition={{ enter: {duration}, exit: {duration} }}>
            <SimpleGrid columns={{
				"base": 1,
				"xl": 2
			}} spacing={2}>
                {sectionsData && sectionsData.map((section, i) => (
                    <SectionCard searchInput={searchInput} key={"section-select-" + section.slug} section={section} {...props}/>
                ))}
            </SimpleGrid>
            {sectionsData && sectionsData.length === 0 && (
                <Alert status="error" variant="solid">
                    <AlertIcon />
                    No results
                </Alert>
            )}
        </Fade>
        </>
    );
}

export default SectionSelect_All;