import React from 'react';

import FormContainer from "../FormContainer";
import TextareaList from "../TextareaList";

import {
    FormLabel,
    Textarea
} from "@chakra-ui/react";


const SEO = (props) => {
    const formName = "SEO";
    const globalForm = props.getForm(formName);

    return (
        <FormContainer
        formName={formName}
        subtext={"What role does SEO/SEO Strategy play in the website project?"}
        {...props}
        >
            <TextareaList cols={1} opts={[
                "Content & copy strategy",
                "Is SEO being considered is a campaign required?",
                "Previous campaign results?",
                "Target area?",
                "Is a separate budget allocated for SEO?",
                "Do you have an SEO strategist or are we providing?",
            ]} formName={formName} {...props}/>

        </FormContainer>
    );
}

export default SEO;