import React from "react";
import {
	Box,
	Spacer,
	Flex,
	Text
} from "@chakra-ui/react";

import hourlyRate from "../../hourlyRate";
import DiscountPrice from "../components/DiscountPrice";

const PriceEstimate = (props) => {
	const { calculateEstimate, steps } = props;
	let estimate = calculateEstimate();

	const extras = steps?.Extras?.data?.data;
	let extraHours = 0;
    const extraHoursData = (extras?.hours || [])?.map(h => {
        h.hours = h.hours ? parseFloat(h.hours) : 0;
        return h;
    });
	extraHoursData.forEach(h => extraHours += h.hours);

	let totalPrice = (estimate.design.time + estimate.development.time + extraHours) * hourlyRate;

	return (
		<Flex pos="absolute" w="100%" bg="white" bottom={0} borderTopWidth={1}>
			<Box p="4">
				Estimate
			</Box>
			<Spacer />
			<Box p="4">

				<DiscountPrice
					totalPrice={totalPrice}
					discountType={extras?.discountType}
					discountVal={extras?.discountVal}
				/>

			</Box>
		</Flex>
	);
}

export default PriceEstimate;