import React from 'react';

import FormContainer from "../FormContainer";

import TextInputList from "../TextInputList";

import {
    Grid,
    Box,
    InputGroup,
    Input,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
    InputLeftElement,
    Heading,
    Text
} from "@chakra-ui/react";

import SVG from "../SVG";

import Icon1 from "../../images/illustrations/online-exam-tests-1.svg";
import Icon2 from "../../images/illustrations/coming-soon-1.svg";
import Icon3 from "../../images/illustrations/analyze-data-1.svg";
import Icon4 from "../../images/illustrations/saving-money-1.svg";

const AdditionalInformation = (props) => {
    const formName = "Additional Information";
    const globalForm = props.getForm(formName);

    const formData = globalForm?.data || {
        technologies: [],
        keyDates: [],
        analytics: [],
        budget: ""
    };

    const updateFormData = (dataKey, dataVals) => {
        formData[dataKey] = dataVals;
        props.setDataCompleted(
            formName,
            Object.values(formData).filter(_=>{
                if(Array.isArray(_)) {
                    return _.length;
                } else if(!isNaN(_)) {
                    return _ > 0;
                } else if(typeof _ === "string") {
                    return _.length;
                }
            }).length === Object.keys(formData).length,
            formData
        );
        props.reload();
    }

    return (
        <FormContainer
        formName={formName}
        formInfo={{
            image: Icon3,
            heading: "Additional Information",
            text: "A few final items are needed for housekeeping before we wrap things up.",
            body: <Box>
                <Text>long body of text if needed</Text>
                <Text>long body of text if needed</Text>
                <Text>long body of text if needed</Text>
            </Box>,
        }}
            //subtext={"What do we want people to do on the site in order to measure success?"}
        {...props}
        >

            <Grid templateColumns={"1fr 2fr"}>
                <Box>
                    <SVG src={Icon1} width="100%"/>
                </Box>
                <Box paddingLeft={10}>
                    <TextInputList
                        {...props}
                        heading={"Technologies"}
                        text={"What platforms / technologies are currently being used and what is desired for the new site?"}
                        btnText={"Add technology"}
                        data={formData.technologies}
                        onChange={(dataVals) => updateFormData("technologies", dataVals)}
                    />
                </Box>
            </Grid>

            <Grid templateColumns={"1fr 2fr"}>
                <Box>
                    <SVG src={Icon2} width="100%"/>
                </Box>
                <Box paddingLeft={10}>
                    <TextInputList
                        {...props}
                        heading={"Key dates"}
                        text={"What are the key dates for this project?"}
                        btnText={"Add date"}
                        type="datedesc"
                        placeholder="Type description here..."
                        data={formData.keyDates}
                        onChange={(dataVals) => updateFormData("keyDates", dataVals)}
                    />
                </Box>
            </Grid>

             <Grid templateColumns={"1fr 2fr"}>
                <Box>
                    <SVG src={Icon3} width="100%"/>
                </Box>
                <Box paddingLeft={10}>
                    <TextInputList
                        {...props}
                        heading={"Site Analytics"}
                        text={"Please list any existing Analytics tracking that you currently use or desire (Eg. Google Analytics, Facebook Pixel)"}
                        btnText={"Add analytics"}
                        data={formData.analytics}
                        onChange={(dataVals) => updateFormData("analytics", dataVals)}
                    />
                </Box>
            </Grid>

            <Grid templateColumns={"1fr 2fr"}>
                <Box>
                    <SVG src={Icon4} width="100%"/>
                </Box>
                <Box paddingLeft={10}>
                    <br />
                    <br />
                    <Heading size={"md"}>{"Budget"}</Heading>
                    <Text>{"What is your budget?"}</Text>
                    <br />
                    <InputGroup>
                        <InputLeftElement
                        pointerEvents="none"
                        color="gray.300"
                        fontSize="1.2em"
                        children="$"
                        />
                        <NumberInput
                            defaultValue={formData.budget || 0}
                            allowMouseWheel={true}
                            onBlur={e => updateFormData("budget", parseFloat(e.target.value))}>
                            <NumberInputField pl={10}/>
    
                        </NumberInput>
                    </InputGroup>
                </Box>
            </Grid>

        </FormContainer>
    );
}

export default AdditionalInformation;