import React from 'react';

import FormContainer from "../FormContainer";

import TextInputList from "../TextInputList";

import {
    Grid,
    Box,
} from "@chakra-ui/react";

import SVG from "../SVG";

import Icon1 from "../../images/illustrations/searching-1.svg";
import Icon2 from "../../images/illustrations/startup-2.svg";

const CallToActions = (props) => {
    const formName = "Competitors & Inspiration";
    const globalForm = props.getForm(formName);

    const formData = globalForm.data || {
        competitors: [],
        inspiration: []
    };

    const updateFormData = (dataKey, dataVals) => {
        formData[dataKey] = dataVals;
        props.setDataCompleted(
            formName,
            Object.values(formData).filter(_=>_.length).length === Object.keys(formData).length,
            formData
        );
        props.reload();
    }

    return (
        <FormContainer
        formName={formName}
            //subtext={"What do we want people to do on the site in order to measure success?"}
        {...props}
        >

            <Grid templateColumns={"1fr 2fr"}>
                <Box>
                    <SVG src={Icon1} width="100%"/>
                </Box>
                <Box paddingLeft={10}>
                    <TextInputList
                        {...props}
                        heading={"Competitor Analysis"}
                        text={"What does the competition look like? Provide links to their websites."}
                        btnText={"Add competitor"}
                        data={formData.competitors}
                        onChange={(dataVals) => updateFormData("competitors", dataVals)}
                    />
                </Box>
            </Grid>

            <Grid templateColumns={"1fr 2fr"}>
                <Box>
                    <SVG src={Icon2} width="100%"/>
                </Box>
                <Box paddingLeft={10}>
                    <TextInputList
                        {...props}
                        heading={"Inspiration"}
                        text={"What other websites do you admire and inspire you?"}
                        btnText={"Add inspiration"}
                        data={formData.inspiration}
                        onChange={(dataVals) => updateFormData("inspiration", dataVals)}
                    />
                </Box>
            </Grid>

        </FormContainer>
    );
}

export default CallToActions;